/*
 *   File : changeAllocation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Change Allocation Success Modal
 *   Integrations :28-11-2023
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

/*import componets*/
import { Input, SearchInput } from '../Input/index';
import { SecondaryBtn, PrimaryBtn } from '../Cta/index';
import { Toast } from '../Note';
import IcoMoon from 'react-icomoon';
import iconSet from "../../Assets/Images/Icons/selection.json";
import { Loader } from '../Loader';

/*import styles*/
import styles from '../../Styles/Components/fund-class.module.scss';

/*import services */
import service from '../../Services/service';
import { CustomTextInput } from '../Form-Elements';

export default function EditAllocation(props) {

    const navigate = useNavigate();

    const [allocation, setAllocation] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState("");
    const [selectedFunds, setSelectedFunds] = useState([]);
    const [disableBtn, setDisableBtn] = useState(true);
    const [search, setSearch] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [fundLoader, setFundLoader] = useState(false);
    const [selectedFundKey, setSelectedFundKey] = useState("");
    const [initialRender, setInitialRender] = useState(true);
    const [rebalanceSuccess, setRebalanceSuccess] = useState(false);
    const [editVersionName, setEditVersionName] = useState(false);
    const [versionName, setVersionName] = useState("");

    useEffect(() => {
        var myModalEl = document.getElementById(props.debt ? "editDebtAllocations" : "editAllocations")
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            props.close()
            $(".modal-backdrop").remove()
            $("body").removeAttr("style")
        })
        //eslint-disable-next-line

        window.addEventListener("click", (event) => {
            if (!event.target.closest(".fund-search")) {
                setSelectedFundKey("");
                setSearchResults([]);
                setSearch("");
            }
        });


        return () => {
            window.removeEventListener("click", (event) => {
                if (event.target.closest(".fund-search")) {
                    setSelectedFundKey("");
                    setSearchResults([]);
                    setSearch("");
                }
            });
        }
    }, []);

    useEffect(() => {
        if (props.editedData && props && Object.values(props.editedData).length > 0) {
            setSelectedFunds(props.editedData);
            setName(props.editedData[0].asset_class);
            let allocation = props.editedData.reduce((a, b) => a + b.allocation, 0);
            setAllocation(allocation);
        }
    }, [props.editedData]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 4000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 4000);
        }
    }, [success]);


    // handle add new fund
    const _handleAddNewFund = () => {
        let new_fund = {
            "id": selectedFunds.length + 1,
        };

        setSelectedFunds([...selectedFunds, new_fund]);
    };

    // handle remove fund
    const _handleRemoveFund = (id) => {
        let updated_basket_list = selectedFunds.filter(item => item.id !== id);
        setSelectedFunds(updated_basket_list);
    };

    // handle search value  change
    const _handleSearchValueChange = (value) => {
        setSearch(value);
        if (value.length >= 3 || value.length === 1) {
            setFundLoader(true);
            _searchFunds(value);
        }
    };

    // handle on search
    const _handleSearchFund = (value) => {
        setFundLoader(true);
        _searchFunds(value);
    };

    // handle clear search
    const _handleClearSearch = () => {
        setSearch("");
    };

    // handle open fund search
    const _handleOpenFundSearchDropdown = (key) => {

        if (selectedFundKey === key) {
            setSelectedFundKey("");
            setSearchResults([]);
        } else {
            setSelectedFundKey(key);
        }

        // Scrolling modal body to make dropdown visible fully
        setTimeout(() => {
            let modal_body_el = document.querySelector(`.modal-body`);
            if (modal_body_el) {
                modal_body_el.scrollTo({ behavior: "smooth", block: "end", top: modal_body_el.scrollHeight, });
            }
        }, 100);
    };

    // handle select fund
    const _handleSelectFund = (selected_fund, id) => {
        let updated_funds = selectedFunds.map(fund => {
            if (fund.id === id) {
                return {
                    ...fund,
                    "fund_name": selected_fund.scheme_name,
                    "scheme_code": selected_fund.scheme_code,
                    "allocation": 0,
                    "asset_class": "Index",
                    "fund_alias": "alias",
                };
            } else {
                return fund
            }
        });

        setSelectedFunds([...updated_funds]);
        setSearch("");
        setSelectedFundKey("");
    };

    // handle change allocation
    const _handleChangeAllocation = () => {
        if (props.basket) {
            _updateBasket()
        } else {
            _updateAllocation()
        }
    };

    // handle navigate to version details page
    const _handleNavigateToVersionDetails = () => {
        navigate("/baskets/version-history");
    };

    // ḥandle edit version name
    const _handleEditVersionName = () => {
        setEditVersionName((prev) => !prev);
    };

    // handle change version name
    const _handleChangeVersionName = (value) => {
        setVersionName(value);
    };


    /*-----update allocation---- */
    const _updateAllocation = () => {

        setLoader(true);
        const requestFunds = []
        selectedFunds.map((item) => {
            return (
                requestFunds.push({
                    "category_type": props.name,
                    "allocation_id": item.id,
                    "allocation": parseFloat(item.allocation),
                    "fund_alias": item.fund_alias
                })
            )
        })
        const url = '/fund/edit-allocation'
        const data = {
            funds: requestFunds
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Allocation updated successfully");


                setTimeout(() => {
                    // $(props.debt ? "#editDebtAllocations" : "#editAllocations").trigger('click');
                    $(".btn-close").trigger("click");
                    props.updatedAllocation(true);
                }, 1000);


            } else {
                setError(response.data.message);
            }

            setLoader(false);
        })
    };

    /*-----update allocation---- */
    const _updateBasket = () => {
        const requestFunds = []
        selectedFunds.map((item) => {
            return (
                requestFunds.push({
                    "scheme_code": item.scheme_code,
                    "allocation": parseFloat(item.allocation),
                    "fund_alias": item.fund_alias
                })
            )
        })
        setLoader(true);
        const url = '/baskets/update'
        const data = {
            basket_id: props.index,
            funds: requestFunds
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Allocation updated successfully");
                setLoader(false);

                setTimeout(() => {
                    // $(props.debt ? "#editDebtAllocations" : "#editAllocations").trigger('click');
                    $(".btn-close").trigger("click")
                    props.updatedAllocation(true);
                }, 1000)
            } else {
                setError(response.data.message);
                setLoader(false);

            }
        })
    };

    const _searchFunds = (search) => {
        const url = '/fund/search'
        const data = {
            query: search,
            page_num: 1,
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSearchResults(response.data);
            }
            else {
                setSearchResults([]);
            }

            setFundLoader(false);
            setInitialRender(false);
        });
    };

    return (
        <>
            <div className={`${styles.e_add_fund} modal fade e-manage-modal`}
                id={props.debt ? "editDebtAllocations" : "editAllocations"}
                tabIndex="-1"
                aria-labelledby="success"
                aria-hidden="true">
                <div className={`${styles.e_modal_dialog} ${rebalanceSuccess ? "" : "modal-lg"} modal-dialog modal-dialog-centered  modal-dialog-scrollable`}>
                    {
                        rebalanceSuccess === false ?
                            <div className="modal-content p-0 h-90vh">
                                <div className="modal-header p-4">
                                    <h6 className='mb-0'>Edit allocations</h6>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-btn" ></button>
                                </div>
                                <div className="modal-body px-4 pb-0">
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label>Asset class</label>
                                            <h6>{name}</h6>
                                        </div>
                                        <div className='col-4'>
                                            <label>Total asset class allocation</label>
                                            <h6>{allocation}%</h6>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='row mt-3'>
                                            <div className='col-4'>
                                                <label>
                                                    Change funds
                                                </label>
                                            </div>
                                            <div className='col-3'>
                                                <label>
                                                    Fund alias
                                                </label>
                                            </div>
                                            <div className='col-3'>
                                                <label>
                                                    Allocation
                                                </label>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-column gap-2 mt-3">
                                            {
                                                selectedFunds.map((item, key) => {
                                                    return (
                                                        <div className='row'>
                                                            <div className='col-4 mb-3'>
                                                                <div className="fund-search position-relative h-100">
                                                                    <div className="d-flex align-items-center  e-border-1px-tab-color px-3 py-1 e-border-radius-8 cursor-pointer h-100"
                                                                        onClick={() => _handleOpenFundSearchDropdown(key)}>
                                                                        <h5 className={`${props.basket && item.fund_name == null && "opacity-50"} e-ellipse mb-0 e-font-14 e-font-manrope  e-font-weight-400 line-height-18px`}>
                                                                            {props.basket ? item.fund_name ? item.fund_name : "Choose a fund" : item.name}
                                                                        </h5>
                                                                    </div>
                                                                    {/* Search fund modal */}
                                                                    {
                                                                        selectedFundKey === key &&
                                                                        <div className="position-absolute top-100 start-0 z-3 mt-2 e-border-1px-tab-color pt-1 e-border-radius-8 bg-white w-100">
                                                                            <div className="fund-search d-flex align-items-center e-bg-search-bg e-border-radius-4 position-relative">
                                                                                <SearchInput placeholder="Search for funds"
                                                                                    value={search}
                                                                                    valueChange={_handleSearchValueChange}
                                                                                    onSearch={_handleSearchFund} />
                                                                                <button className={`${search.length === 0 ? "invisible" : ""} btn-close w-10px me-2`} onClick={_handleClearSearch}></button>
                                                                            </div>
                                                                            {/* search Results */}
                                                                            {
                                                                                fundLoader === false ?
                                                                                    <>
                                                                                        {
                                                                                            searchResults.length !== 0 ?
                                                                                                <div className="d-flex flex-column gap-2 px-2 mt-1 overflow-scroll no-scrollbar h-160px">
                                                                                                    {
                                                                                                        searchResults.map(fund => (
                                                                                                            <span className='cursor-pointer mb-0 e-font-14 e-font-manrope  e-font-weight-400 line-height-18px'
                                                                                                                onClick={() => _handleSelectFund(fund, item.id)}>
                                                                                                                {fund.scheme_name}
                                                                                                            </span>
                                                                                                        ))
                                                                                                    }

                                                                                                </div>
                                                                                                :
                                                                                                <div className="h-160px pt-5">
                                                                                                    <p className='text-center mb-0 e-font-14 e-font-manrope  e-font-weight-400 line-height-18px'>
                                                                                                        {initialRender === true || search.length === 0 ? "Search funds!" : "No founds"}
                                                                                                    </p>
                                                                                                </div>
                                                                                        }
                                                                                    </>

                                                                                    :
                                                                                    <div className="h-160px">
                                                                                        <Loader loaderClassName={"edit-fund-loader"} />
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <Input type="text"
                                                                    disabled={true}
                                                                    value={item.fund_alias}
                                                                    valueChange={(value) => {
                                                                        let temp_schemes = [...selectedFunds]

                                                                        if (value === "") {
                                                                            temp_schemes[key].fund_alias = null;
                                                                        } else {
                                                                            temp_schemes[key].fund_alias = value;
                                                                        }

                                                                        setSelectedFunds(temp_schemes)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='col-3 position-relative'>
                                                                <Input type="number"
                                                                    value={item.allocation}
                                                                    suffix="%"
                                                                    valueChange={(value) => {
                                                                        if (value.length <= 3) {
                                                                            let temp_funds = [...selectedFunds]
                                                                            temp_funds[key].allocation = parseInt(value);
                                                                            const temp_allocation = temp_funds.reduce((a, b) => a + b.allocation, 0);
                                                                            const allocation = temp_allocation ? temp_allocation : 0;
                                                                            setAllocation(allocation);
                                                                            setSelectedFunds(temp_funds);
                                                                            setDisableBtn(false);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-2 mb-3 align-self-center">
                                                                <span className="d-flex align-items-center ps-2 e-text-badge-fail e-font-manrope  e-font-weight-400 line-height-18px e-font-14 cursor-pointer"
                                                                    onClick={() => _handleRemoveFund(item.id)}>
                                                                    <IcoMoon iconSet={iconSet}
                                                                        icon={"delete-trash"}
                                                                        className='h-16 me-1'
                                                                        color={"#DE2020"} />
                                                                    Remove
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pt-0">
                                    {/* Action btns */}
                                    <div className="row w-100">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between">


                                                {/* Add more fund button */}
                                                {
                                                    selectedFunds.length <= 5 &&
                                                    <span span className="d-flex align-items-center  gap-2 e-text-primary e-font-manrope  e-font-weight-400 line-height-18px e-font-14 cursor-pointer"
                                                        onClick={_handleAddNewFund}>
                                                        <IcoMoon iconSet={iconSet}
                                                            icon={"add-plus"}
                                                            size={16}
                                                            className='' />
                                                        Add new fund
                                                    </span>
                                                }

                                                <div className="d-flex justify-content-end ms-auto">
                                                    <SecondaryBtn
                                                        name="Cancel"
                                                        className={`${styles.e_cancel} e-cancel col-auto px-4`}
                                                        cancel="modal"
                                                        handleClick={() => setAllocation("")}
                                                    />
                                                    {
                                                        selectedFunds.filter((item) => item.allocation !== '').length === selectedFunds.length && selectedFunds.filter((item) => item.fund_alias !== null).length === selectedFunds.length ?
                                                            <PrimaryBtn
                                                                disabled={disableBtn || (allocation < 1 || allocation > 100) ? true : false}
                                                                id="save-btn"
                                                                name="Change"
                                                                className={loader ? "e-modal-submit e-btn-loader" : ""}
                                                                handleClick={_handleChangeAllocation}
                                                            />
                                                            :
                                                            <PrimaryBtn
                                                                id="save-btn"
                                                                name="Change"
                                                                className='e-modal-submit e-disabled'
                                                            />
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :

                            <div className="modal-content p-0">
                                <div className="modal-header p-4">
                                    <h6 className='mb-0 text-center w-100 e-font-16 e-font-weight-700'>Changes Saved</h6>
                                </div>
                                <div className="modal-body p-4 pt-0">
                                    <span className='e-font-14 e-font-weight-400 e-text-secondary-grey e-font-manrope line-height-22px'>
                                        Your rebalancing changes have been successfully saved, and a new version has been created.
                                    </span>
                                    <div className={`${editVersionName ? "mb-3" : "mb-4"} d-flex flex-column mt-2`}>
                                        <span className='e-font-14 e-font-weight-500 e-text-secondary-grey e-font-manrope line-height-22px'>
                                            Version Name:
                                        </span>
                                        <span className='d-flex gap-2 e-font-14 e-font-weight-500 e-text-primary e-font-manrope line-height-22px'>
                                            Index Balanced Version 146
                                            <span className='cursor-pointer e-font-weight-600 text-decoration-underline link-offset-2'
                                                onClick={_handleEditVersionName}>
                                                Edit
                                            </span>
                                        </span>

                                        {/* Edit version name - input */}
                                        {
                                            editVersionName === true &&
                                            <div className='mt-3'>
                                                <CustomTextInput
                                                    placeholder="Enter new name"
                                                    type="text"
                                                    value={versionName}
                                                    // errorMsg={fatherNameError}
                                                    labelStyle="e-font-weight-500"
                                                    handleChange={_handleChangeVersionName} />
                                            </div>
                                        }
                                    </div>




                                    {/* Action btns */}
                                    <div className="d-flex justify-content-end ms-auto">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className={`${styles.e_cancel} e-cancel col-auto px-4`}
                                            cancel="modal"
                                            handleClick={() => setAllocation("")}
                                        />
                                        <PrimaryBtn
                                            id="save-btn"
                                            name="View Version"
                                            cancel="modal"
                                            handleClick={_handleNavigateToVersionDetails}
                                        />
                                    </div>
                                </div>
                            </div>

                    }
                </div>

            </div >
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }
        </>
    );
}
