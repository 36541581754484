/*
 *   File : transactions.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Holdingscontainer;
 *   Integrations :28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
*/
/*import packages*/
import React, { useState, useEffect } from 'react';

/*import components*/
import { LineChartMarker } from '../../Components/Charts';
// import { IconStyleToolTip } from '../../components/badge';

/*import assets*/
// import Icon1 from '../../assets/images/dataFormating/icon1.svg';
// import Icon2 from '../../assets/images/dataFormating/icon2.svg';
// import Icon from '../../assets/images/dataFormating/Icon.svg'

/* import components */

import { Loader } from '../../Components/Loader';
import { EmptyNote } from '../../Components/Note';

import service from '../../Services/service';

/*import services*/


const Holdings = (props) => {
    // const wrapperRef = useRef(null);
    // const [tooltip, setTooltip] = useState(false);
    const [loader, setLoader] = useState(true);
    const [summaryData, setSummaryData] = useState({});
    // const [labels, setLabels] = useState([]);
    // const [targetData, setTargetData] = useState([]);
    // const [performanceData, setPerformanceDate] = useState([]);
    // const [images, setImages] = useState([])
    const [goalData, setGoalData] = useState([]);
    const [fundData, setFundData] = useState([]);
    const [basketData, setBasketData] = useState([]);

    useEffect(() => {
        _getHoldingsData() //api call
        //eslint-disable-next-line
    }, [])


    /*------ get holdings details -----------*/
    const _getHoldingsData = () => {
        const url = '/user/holdings';

        const data = {
            user_id: Number(props.id)
        }
        service(true, url, data).then((response) => {

            if (response.status_code === 200) {
                setSummaryData(response.data.summary);
                // let tempLabels = []
                // let tempData = []
                // let tempImages = []
                // let tempTarget = []

                // tempLabels.push(0)
                // tempData.push(0)
                // tempImages.push({
                //     src: ""
                // })
                // tempTarget.push(0)

                // response.data.goals.map((item) => {
                //     return (
                //         tempLabels.push(item.target_year)
                //     )
                // })

                // response.data.goals.map((item) => {
                //     return (
                //         tempData.push(item.invested_amount)
                //     )
                // })

                // response.data.goals.map((item) => {
                //     return (
                //         tempImages.push({ src: item.icon })
                //     )
                // })
                // response.data.goals.map((item) => {
                //     return (
                //         tempTarget.push(item.target)
                //     )
                // })

                // const maximum = Math.max(...tempData)

                // for (let i = 1; i < response.data.goals.length; i++) {
                //     tempTarget.push(maximum)
                // }

                // setLabels(tempLabels);
                // setPerformanceDate(tempData);
                // setImages(tempImages);
                // setTargetData(tempTarget);
                setGoalData(response.data.goals);
                setFundData(response.data.funds);
                setBasketData(response.data.baskets)
            } else {
                setSummaryData({});
                // setLabels([]);
                setLoader(false)
                // setPerformanceDate([])
                // setTargetData([]);
                setGoalData([]);
            }

            setLoader(false)
        })
    }

    return (
        <>
            {
                loader ?
                    <div className="card e-dashboard-card border-0 p-4 pt-5 e-profile-holdings">
                        <Loader />
                    </div>
                    :
                    summaryData.current_amount ?
                        <>
                            <div className="row">
                                {/* Summary Section */}
                                <div className="col">
                                    <div className="card e-dashboard-card border-0 e-profile-holdings">

                                        <h4 className=''>Portfolio summary</h4>

                                        <div className="d-flex gap-5 row-gap-3 flex-wrap">
                                            <div className="d-flex flex-column gap-1">
                                                <span className="e-summary-title">
                                                    Current value
                                                </span>
                                                <p className={`e-summary-value ${summaryData.current_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                    {summaryData.current_amount_text}
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column gap-1">
                                                <span className="e-summary-title">
                                                    Total returns
                                                </span>
                                                <p className={`e-summary-value e-text-grey ${summaryData.returns_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                    {summaryData.returns_amount_text}
                                                    <span>
                                                        ({summaryData.returns_percentage_text})
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column gap-1">
                                                <span className="e-summary-title">Amount invested</span>
                                                <p className="e-summary-value e-text-grey">{summaryData.invested_amount_text}</p>
                                            </div>
                                            <div className="d-flex flex-column gap-1">
                                                <span className="e-summary-title">Processing amount</span>
                                                <p className="e-summary-value e-text-grey">{summaryData.processing_amount_text}</p>
                                            </div>
                                            <div className="d-flex flex-column gap-1">
                                                <span className="e-summary-title">Goal target amount</span>
                                                <p className="e-summary-value e-text-grey">{summaryData.goal_target_amount_text}</p>
                                            </div>
                                            <div className="d-flex flex-column gap-1">
                                                <span className="e-summary-title">XIRR</span>
                                                <p className={`e-summary-value e-text-grey ${summaryData.xirr < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                    {summaryData.xirr_text}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Performance chart */}
                                {/* <div className="d-none col-lg-8 col-12 mt-lg-0 mt-md-3 mt-3">
                                <div className="card e-dashboard-card border-0 e-profile-holdings position-relative">
                                    <h4 >
                                        Plan overview
                                        <img
                                            className="ps-2"
                                            src={Icon} ref={wrapperRef} alt=""
                                            onMouseEnter={() => setTooltip(true)}
                                            onMouseLeave={() => setTooltip(false)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </h4>
                                    {
                                        tooltip ?
                                            <IconStyleToolTip
                                                className="e-icon-tooltip"
                                                more={true}
                                                content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />

                                            :
                                            null
                                    }
                                    <div className='row'>
                                        {
                                            loader ?
                                                <Loader />
                                                :
                                                performanceData.length > 0 && labels.length > 0 && images.length > 0 ?
                                                    <>
                                                        <div className='col-lg-6'>

                                                            <LineChartMarker

                                                                performace={performanceData}
                                                                categories={labels}
                                                                images={images}
                                                                target={targetData} />
                                                        </div>
                                                        <div className='col-lg-5'>
                                                            <div className="e-graph-data-wrapper1">
                                                                <div className="d-inline-flex" style={{ width: "100%" }}>
                                                                    <span className="e-graph-class e-target-class"></span>
                                                                    Target

                                                                </div>
                                                                <div className="d-inline-flex" style={{ width: "100%" }}>
                                                                    <span className="e-graph-class e-performance-class"></span>
                                                                    Current performance
                                                                </div>
                                                            </div>
                                                            <div className="e-graph-data-wrapper2 mt-2">
                                                                {
                                                                    goalData.map((item) => {
                                                                        return (
                                                                            <div className="row d-flex align-items-center mb-2">
                                                                                <div className="col-lg-2 col-2 pe-0">
                                                                                    <img src={item.icon} alt="" width="28px" />
                                                                                </div>
                                                                                <div className="col-lg-10 col-10 ps-0  ps-2">
                                                                                    <p className="ps-0 mb-0">{item.name}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <EmptyNote note="No data" />
                                        }
                                    </div>
                                </div>
                            </div> */}

                            </div>
                            {
                                fundData && fundData.length !== 0 && (
                                    <div className='row pt-3'>
                                        <div className='col-lg-12 '>
                                            <div className='card e-dashboard-card border-0 e-profile-holdings'>
                                                <h6>Invested in funds</h6>
                                                <div className={`e-investment-wrapper overflow-auto e-manage-scrollbar`}>
                                                    <div className='row flex-nowrap'>
                                                        <div className='col-5 col-lg-4'>
                                                            <h6 className='text-nowrap'>Fund name</h6>
                                                        </div>
                                                        <div className='col-5 col-lg-2'>
                                                            <h6 className='text-nowrap'>Folio</h6>
                                                        </div>
                                                        <div className='col-5 col-lg-2'>
                                                            <h6 className='text-nowrap'>Units</h6>
                                                        </div>
                                                        <div className='col-3 col-lg-2'>
                                                            <h6 className=''>Amount invested</h6>
                                                        </div>
                                                        <div className='col-3 col-lg-2'>
                                                            <h6 className='text-nowrap'>Current value</h6>
                                                        </div>
                                                        <div className='col-3 col-lg-2'>
                                                            <h6 className=''>Processing Amount</h6>
                                                        </div>
                                                        <div className='col-3 col-lg-2'>
                                                            <h6 className='text-nowrap'>Returns</h6>
                                                        </div>
                                                    </div>
                                                    {
                                                        fundData.map((item, key) => {
                                                            return (
                                                                <div key={key} className='row mb-4 flex-nowrap'>
                                                                    <div className='col-5 col-lg-4 d-flex'>
                                                                        <img src={item.icon}
                                                                            alt="fund logo"
                                                                            width={28}
                                                                            height={28}
                                                                            className='me-2 bg-white e-border-radius-4 object-fit-contain padding-2px-all' />
                                                                        <p className='mb-0'>{item.name}</p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className='pt-3 e-text-grey'>{item.folios}</p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className='pt-3 e-text-grey text-nowrap'>{item.units}</p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className='pt-3 e-text-grey text-nowrap'>{item.invested_amount_text}</p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className={`pt-3 text-nowrap ${item.current_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                                            {item.current_amount_text}
                                                                        </p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className='pt-3 text-nowrap'>{item.processing_amount_text}</p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className={`pt-3 text-nowrap ${item.returns_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                                            {item.returns_amount_text}({item.returns_percentage_text})
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                basketData && basketData.length !== 0 && (
                                    <div className='row pt-3'>
                                        <div className='col-lg-12 '>
                                            <div className='card e-dashboard-card border-0 e-profile-holdings'>
                                                <h6>Invested in baskets</h6>
                                                <div className="e-bg-ghost-white e-border-radius-8">
                                                    {
                                                        basketData.map((item, key) => (
                                                            <div className={`e-investment-wrapper overflow-auto e-manage-scrollbar bg-transparent`}>
                                                                <div className='row flex-nowrap'>
                                                                    <div className='col-5 col-lg-2'>
                                                                        <h6 className='text-nowrap'>Invested Baskets</h6>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <h6 className='text-nowrap'>Current value</h6>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <h6>Amount invested</h6>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <h6>Processing Amount</h6>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <h6 className='text-nowrap'>Returns</h6>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <h6 className='text-nowrap'>XIRR</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div key={key} className='row mb-0 flex-nowrap'>
                                                                        <div className='col-5 col-lg-2 d-flex align-items-center'>
                                                                            <img src={item.icon}
                                                                                alt="fund logo"
                                                                                width={28}
                                                                                height={28}
                                                                                className='me-2 bg-white e-border-radius-4 object-fit-contain padding-2px-all' />
                                                                            <p className='mb-0'>{item.name}</p>
                                                                        </div>
                                                                        <div className='col-3 col-lg-2'>
                                                                            <p className={`pt-3 text-nowrap ${item.current_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                                                {item.current_amount_text}
                                                                            </p>
                                                                        </div>
                                                                        <div className='col-3 col-lg-2'>
                                                                            <p className='pt-3 e-text-grey text-nowrap'>{item.invested_amount_text}</p>
                                                                        </div>
                                                                        <div className='col-3 col-lg-2'>
                                                                            <p className='pt-3 text-nowrap'>{item.processing_amount_text}</p>
                                                                        </div>
                                                                        <div className='col-3 col-lg-2'>
                                                                            <p className={`pt-3 text-nowrap ${item.returns_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                                                {item.returns_amount_text}({item.returns_percentage_text})
                                                                            </p>
                                                                        </div>
                                                                        <div className='col-3 col-lg-2'>
                                                                            <p className={`pt-3 text-nowrap ${item.xirr < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                                                {item.xirr_text}
                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                    {/* Associated fund details */}
                                                                    <div className=" bg-white p-3 e-border-radius-8 mb-4 w-100">
                                                                        <div className='row'>
                                                                            <div className='col-lg-12'>
                                                                                <div className=''>
                                                                                    <h6>Fund details</h6>
                                                                                    <div className={`e-investment-wrapper overflow-auto e-manage-scrollbar`}>
                                                                                        <div className='row flex-nowrap'>
                                                                                            <div className='col-5 col-lg-4'>
                                                                                                <h6 className='text-nowrap'>Fund name</h6>
                                                                                            </div>
                                                                                            <div className='col-5 col-lg-2'>
                                                                                                <h6 className='text-nowrap'>Folio</h6>
                                                                                            </div>
                                                                                            <div className='col-5 col-lg-2'>
                                                                                                <h6 className='text-nowrap'>Units</h6>
                                                                                            </div>
                                                                                            <div className='col-3 col-lg-2'>
                                                                                                <h6 className=''>Amount invested</h6>
                                                                                            </div>
                                                                                            <div className='col-3 col-lg-2'>
                                                                                                <h6 className='text-nowrap'>Current value</h6>
                                                                                            </div>
                                                                                            <div className='col-3 col-lg-2'>
                                                                                                <h6 className=''>Processing Amount</h6>
                                                                                            </div>
                                                                                            <div className='col-3 col-lg-2'>
                                                                                                <h6 className='text-nowrap'>Returns</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            item.fund_detail.map((item, key) => {
                                                                                                return (
                                                                                                    <div key={key} className='row mb-4 flex-nowrap'>
                                                                                                        <div className='col-5 col-lg-4 d-flex'>
                                                                                                            <img src={item.image}
                                                                                                                alt="fund logo"
                                                                                                                width={28}
                                                                                                                height={28}
                                                                                                                className='me-2 bg-white e-border-radius-4 object-fit-contain padding-2px-all' />
                                                                                                            <p className='mb-0'>{item.fund_name}</p>
                                                                                                        </div>
                                                                                                        <div className='col-3 col-lg-2'>
                                                                                                            <p className='pt-3 e-text-grey text-nowrap'>{item.folios}</p>
                                                                                                        </div>
                                                                                                        <div className='col-3 col-lg-2'>
                                                                                                            <p className='pt-3 e-text-grey text-nowrap'>{item.units}</p>
                                                                                                        </div>
                                                                                                        <div className='col-3 col-lg-2'>
                                                                                                            <p className='pt-3 e-text-grey text-nowrap'>{item.invested_amount_text}</p>
                                                                                                        </div>
                                                                                                        <div className='col-3 col-lg-2'>
                                                                                                            <p className={`pt-3 text-nowrap ${item.current_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                                                                                {item.current_amount_text}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className='col-3 col-lg-2'>
                                                                                                            <p className='pt-3 text-nowrap'>{item.processing_amount_text}</p>
                                                                                                        </div>
                                                                                                        <div className='col-3 col-lg-2'>
                                                                                                            <p className={`pt-3 text-nowrap ${item.returns_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                                                                                {item.returns_amount_text}({item.returns_percentage_text})
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                goalData && goalData.length !== 0 && (
                                    <div className='row pt-3'>
                                        <div className='col-lg-12 '>
                                            <div className='card e-dashboard-card border-0 e-profile-holdings'>
                                                <h6>Invested in goals</h6>
                                                <div className={`e-investment-wrapper overflow-auto e-manage-scrollbar`}>
                                                    <div className='row flex-nowrap'>
                                                        <div className='col-5 col-lg-4'>
                                                            <h6 className='text-nowrap'>Invested Goals</h6>
                                                        </div>
                                                        <div className='col-3 col-lg-2'>
                                                            <h6 className='text-nowrap'>Current value</h6>
                                                        </div>
                                                        <div className='col-3 col-lg-2'>
                                                            <h6>Amount invested</h6>
                                                        </div>
                                                        <div className='col-3 col-lg-2'>
                                                            <h6>Processing Amount</h6>
                                                        </div>
                                                        <div className='col-3 col-lg-2'>
                                                            <h6 className='text-nowrap'>Returns</h6>
                                                        </div>
                                                    </div>
                                                    {
                                                        goalData.map((item, key) => {
                                                            return (
                                                                <div key={key} className='row mb-4 flex-nowrap'>
                                                                    <div className='col-5 col-lg-4 d-flex align-items-center'>
                                                                        <img src={item.icon}
                                                                            alt="fund logo"
                                                                            width={28}
                                                                            height={28}
                                                                            className='me-2 bg-white e-border-radius-4 object-fit-contain padding-2px-all' />
                                                                        <p className='mb-0'>{item.name}</p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className={`pt-3 text-nowrap ${item.current_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                                            {item.current_amount_text}
                                                                        </p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className='pt-3 e-text-grey text-nowrap'>{item.invested_amount_text}</p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className='pt-3 text-nowrap'>{item.processing_amount_text}</p>
                                                                    </div>
                                                                    <div className='col-3 col-lg-2'>
                                                                        <p className={`pt-3 text-nowrap ${item.returns_amount < 0 ? "e-text-red" : "e-text-go-green"}`}>
                                                                            {item.returns_amount_text}({item.returns_percentage_text})
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                        :
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='card e-dashboard-card border-0 e-profile-holdings min-vh-100'>
                                    <EmptyNote note="No data found" />
                                </div>
                            </div>
                        </div>
            }
        </>

    )
}

export default Holdings;

