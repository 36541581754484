/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import packages*/
import React from 'react';
import DataTable from 'react-data-table-component';

export default function Datatable(props) {

	// const handleSort = (column, sortDirection) => {
	// 	console.log("Column sorted:", column.name);
	// 	console.log("Sort direction:", sortDirection);
	// };

	return (
		<>
			<DataTable
				paginationServer={props.paginationServer}
				columns={props.columns}
				data={props.data}
				paginationPerPage={props.perPage ? props.perPage : 10}
				pagination={props.pagination}
				onRowClicked={(row) => props.rowClick ? props.rowClick(row) : null}
				selectableRowsHighlight={true}
				selectableRows={props.selectableRows}
				clearSelectedRows={props.clearSelectedRows}
				onSelectedRowsChange={(row) => props.selectRows(row)}
				customStyles={props.customStyles}
				// onSort={props.handleSort}
				{...props}
			/>
		</>
	);
};


Datatable.defaultProps = {
	selectRows: () => { }
};



