/*
 *   File : orders.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Orders file for Orders container;
 *   Integrations : 28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
*/
/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import moment from 'moment';

/*import components*/
import { DataTable } from '../../Components/DataFormating/index';
import { FilterBtn, DownloadBtn } from '../../Components/Cta/index';
import { OrderDetail } from '../../Components/Modal/index';
import { SearchInput, DateRangePicker } from '../../Components/Input/index';
import SelectBox from '../../Components/Dropdown/select-box';
import { Loader } from '../../Components/Loader';
import { Toast } from '../../Components/Note';
import { EmptyNote } from '../../Components/Note';
import Tooltip from '../../Components/Badge/tooltip';

/*import styles*/
import styles from '../../Styles/Components/search-input.module.scss';

/*import assets*/
import View from '../../Assets/Images/DataFormating/view.svg';



/* import services */

import service from '../../Services/service';

const Orders = (props) => {

	const wrapperRef = useRef(null);
	const navigate = useNavigate();
	const ORDER_STATUS_OPTIONS = useSelector(state => state.INDEX_FUND_STORE.ORDER_STATUS);
	const PAYMENT_STATUS_OPTIONS = useSelector(state => state.INDEX_FUND_STORE.PAYMENT_STATUS);
	const TYPE_OPTIONS = useSelector(state => state.INDEX_FUND_STORE.ORDER_TYPE_OPTIONS);
	const INVESTMENT_OPTIONS = useSelector(state => state.INDEX_FUND_STORE.INVESTMENT_TYPE_OPTIONS);

	const [search, setSearch] = useState("");
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState(null);
	const [more, setMore] = useState(false);
	const [refKey, setRefKay] = useState();
	const [loader, setLoader] = useState(true);
	const [error, setError] = useState(null);
	const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
	const [selectedPayStatus, setSelectedPayStatus] = useState(null);
	const [check, setCheck] = useState(null)
	const [show, setShow] = useState(null);
	const [orderData, setOrderData] = useState([]);
	const [details, setDetails] = useState({});
	const [success, setSuccess] = useState(null);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [investmentType, setInvestmentType] = useState(INVESTMENT_OPTIONS[0]);
	const [type, setType] = useState(TYPE_OPTIONS[0]);
	const [tableRowsPerPage, setTableRowsPerPage] = useState(15);
	const [totalTableRows, setTotalTableRows] = useState(15);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		document.addEventListener('mouseup', _closeMenu);
		return () => {
			document.removeEventListener('mouseup', _closeMenu);
		};

	}, []);

	useEffect(() => {
		if (INVESTMENT_OPTIONS.length > 0) {
			setInvestmentType(INVESTMENT_OPTIONS[0])
		};
	}, [INVESTMENT_OPTIONS]);

	useEffect(() => {
		if (dates !== null && selectedPayStatus !== null && selectedOrderStatus !== null && type && investmentType) {
			setLoader(true);
			let page_number = 1;
			_getOrderList(search, tableRowsPerPage, page_number);
			setCurrentPage(page_number);
		}
	}, [dates, selectedPayStatus, selectedOrderStatus, type, investmentType]);

	useEffect(() => {
		setTimeout(() => {
			if (error !== null) {
				setError(null);
			}
		}, 3000);
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null)
			}, 3000)
		}
	}, [success])

	const _customDateSort = (a, b) => {
		const dateA = new Date(a.order_time);
		const dateB = new Date(b.order_time);
		if (dateA < dateB) {
			return -1;
		} else if (dateA > dateB) {
			return 1
		} else {
			return 0
		}
	};

	// Updating filter values from sessionStorage
	useEffect(() => {
		if (sessionStorage.getItem("order_status")) {
			setSelectedOrderStatus(JSON.parse(sessionStorage.getItem("order_status")));
			setFilterDown(true);
		} else if (ORDER_STATUS_OPTIONS.length > 0) {
			setSelectedOrderStatus(ORDER_STATUS_OPTIONS[0]);
		}
		//eslint-disable-next-line
	}, [ORDER_STATUS_OPTIONS]);

	// Updating filter values from sessionStorage
	useEffect(() => {
		if (sessionStorage.getItem("payment_status")) {
			setSelectedPayStatus(JSON.parse(sessionStorage.getItem("payment_status")));
			setFilterDown(true);
		} else if (PAYMENT_STATUS_OPTIONS.length > 0) {
			setSelectedPayStatus(PAYMENT_STATUS_OPTIONS[0]);
		}
		//eslint-disable-next-line
	}, [PAYMENT_STATUS_OPTIONS]);

	// Updating filter values from sessionStorage
	useEffect(() => {

		// Filter type
		if (sessionStorage.getItem("type")) {
			setType(JSON.parse(sessionStorage.getItem("type")));
			setFilterDown(true);
		} else {
			setType(TYPE_OPTIONS[0]);
		}

		// Filter order_range
		if (sessionStorage.getItem("order_range")) {
			setDates(JSON.parse(sessionStorage.getItem("order_range")));
			setFilterDown(true);
		} else {
			setDates({
				start: null,
				end: null
			});
		}

		// Filter investment_type
		if (sessionStorage.getItem("investment_type")) {
			setInvestmentType(JSON.parse(sessionStorage.getItem("investment_type")));
			setFilterDown(true);
		} else {
			setInvestmentType(INVESTMENT_OPTIONS[0]);
		}

		// Filter order_search
		if (sessionStorage.getItem("order_search")) {
			setSearch(JSON.parse(sessionStorage.getItem("order_search")));
		} else {
			setSearch('');
		}

	}, []);

	/*--- func to open order detail modal ---*/
	const _modalOpen = (row) => {
		setDetails(row);
		var myModal = new Modal(document.getElementById("orderDetail"), {});
		myModal.show();
	};

	const _closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false);
		}
	};

	// handle table rows per table change
	const _handlePerRowsChange = (page_size) => {
		setTableRowsPerPage(page_size);
		// API
		_getOrderList("", page_size, 1);
		setCurrentPage(1);
	};

	// handle table rows per table change
	const _handleTablePageChange = (current_page) => {
		setCurrentPage(current_page);
		// API
		_getOrderList("", tableRowsPerPage, current_page);
	};

	/* --------selecting rows---------*/
	const _handleChange = (row) => {
		let selected = []
		if (row.allSelected) {
			setCheck(selected);
		} else {
			for (let i = 0; i < row.selectedRows.length; i++) {
				selected.push(row.selectedRows[i]['order_id']);
			}
			setCheck(selected);
		}
	};

	/*--------- order list -----------*/
	const _getOrderList = (query, page_size, page_number) => {
		setCheck(null);
		setLoader(true)
		const url = '/order/list-order';
		const data = {
			from_date: dates.start,
			to_date: dates.end,
			order_status: selectedOrderStatus.value,
			payment_status: selectedPayStatus.value,
			query: query ? (query.value) : search,
			type: type.value,
			investment_type: investmentType?.value,
			page_num: page_number || currentPage,
			page_size: page_size || tableRowsPerPage
		};

		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setOrderData(response.data.orders);
				setCheck(null);
			} else {
				setOrderData([]);
			}

			setTotalTableRows(response.data.total_orders);

			setLoader(false);
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});

		});

	};

	/*---- download orders--*/
	const _downloadOrderList = () => {

		const url = '/order/list-download';
		const data = {
			user_id: Number(props.id),
			order_ids: check
		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					let a = document.createElement("a");
					a.href = response.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setSearch("");
					setCheck(null);
					setSuccess("Order list downloaded successfully")
				} else {
					setError(response.data.message)
				}

				setDownloadLoader(false);
			}
			else {
				setDownloadLoader(false);
				setError(response.data.message);
			}
		})
	};

	/*---- order table columns -----*/

	const ORDER_COLUMNS = [
		{
			name: 'Order ID',
			selector: (row) => row.order_no ? row.order_no : '--',
			sortable: true,
			left: true,
			minWidth: "180px",
			maxWidth: "180px",
			defaultSortAsc: false,
		},
		{
			name: 'FP ID',
			selector: (row) => row.order_id ? row.order_id : '--',
			sortable: true,
			left: true,
			minWidth: "110px",
			maxWidth: "110px",
			defaultSortAsc: false,
		},
		{
			name: 'Name',
			selector: 'customer_name',
			sortable: true,
			left: true,
			minWidth: '200px',
			cell: row => <>
				{
					row.name ?
						<div className='e-link link-offset-1' onClick={() => {
							navigate(`/user-detail?id=${row.customer_id}&tab=${2}`)
						}}>{row.customer_name}</div>
						:
						"--"
				}
			</>
			//  navigate(path, {
			// 	state: {
			// 		data: data,
			// 	}
			// });
		},
		{
			name: 'Created on',
			selector: row => row.order_time,
			sortable: true,
			left: true,
			minWidth: '200px',
			sortFunction: (a, b) => _customDateSort(a, b, 'order_time',),
			cell: row => <div className="col-lg-12" onClick={() => _modalOpen(row)}>
				{row.order_time}
			</div>
		},
		{
			name: 'Type',
			selector: 'type',
			sortable: true,
			left: true,
			minWidth: '150px',
			cell: (row) => <div className="col-lg-12" onClick={() => _modalOpen(row)} >
				{row.type || "--"}
			</div>
		},
		{
			name: 'Amount',
			selector: row => row.amount ? Number(row.amount.replaceAll(/[₹,]/g, "")) : 0,
			// selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
			sortable: true,
			left: true,
			minWidth: '130px',
			cell: row => <div onClick={() => _modalOpen(row)}>
				{
					row.amount ?
						row.amount
						:
						0
				}
			</div>

		},
		{
			name: 'Fund name',
			left: true,
			minWidth: "200px",
			cell: row => <div onClick={() => _modalOpen(row)} className="py-2">
				{row.name}
			</div>,
			omit: investmentType?.value === 3 ? true : false

		},
		{
			name: 'Basket',
			selector: 'basket_name',
			sortable: true,
			left: true,
			minWidth: '120px',
			cell: (row) => <div className="col-lg-12" onClick={() => _modalOpen(row)} >
				{row.basket_name}
			</div>,
			omit: investmentType?.value === 3 ? false : true
		},
		{
			name: 'Payment method',
			left: true,
			minWidth: "180px",
			cell: row => <div onClick={() => _modalOpen(row)}>
				{
					row.payment_type_id === null ?
						"--"
						: row.payment_type_id === 1 ?
							"Net banking" : "UPI"
				}
			</div>

		},
		{
			name: 'Order status',
			selector: 'order_status',
			sortable: false,
			left: true,
			minWidth: '170px',
			cell: row => <>{row.order_status ?
				<div className="d-inline-flex">
					<div className="col-lg-10">
						<div data-tag="allowRowEvents"
							className={
								row.order_status === "Partially Success" ? `e-status-badge e-status-badge-partially` :
									row.order_status === "Success" ? `e-status-badge e-status-badge-success` :
										row.order_status === "Failed" ? `e-status-badge e-status-badge-fail` :
											row.order_status === "Pending" ? `e-status-badge e-status-badge-pending` :
												'e-status-badge'
							}>
							{
								row.order_status
							}
						</div>
					</div>
				</div>
				:
				"--"}</>
		},
		{
			name: 'Payment status',
			selector: 'payment_status',
			sortable: false,
			left: true,
			minWidth: "170px",

			// cell: row => <div className="">
			//   <Badge type="success" text="success"/>
			// </div>

			cell: (row, key) => (
				<>
					{
						row.payment_status && row.type !== "Switch" ?
							<div className="d-inline-flex">
								<div className="col-lg-10">
									<div data-tag="allowRowEvents"
										className={
											row.payment_status === "Partially Success" ? `e-status-badge e-status-badge-partially` :
												row.payment_status === "Success" ? `e-status-badge e-status-badge-success` :
													row.payment_status === "Failed" ? `e-status-badge e-status-badge-fail` :
														row.payment_status === "Pending" ? `e-status-badge e-status-badge-pending` :
															row.payment_status === "Processing" ? `e-status-badge e-status-badge-pending` :
																'e-status-badge'
										}>
										{row.payment_status.toLowerCase()}
									</div>
								</div>
								<div className="col-lg-2">
									<span data-tag="allowRowEvents"
										onMouseEnter={() => setShow(key + 1)} onMouseLeave={() => setShow(null)} className={
											row.payment_status === "Failed" ? `e-icon` :
												row.payment_status === "Processing" ? `e-icon` :
													null
										}>
									</span>
								</div>
								{
									show === (key + 1) ?

										<Tooltip className={"min-width-170px left-25-per"}
											content={row.failure_reason ? row.failure_reason : row.payment_status === "Processing" ? "Transaction pending" : "Transaction failed"} />
										:
										null
								}
							</div>
							:
							"--"}
				</>
			)
		},
		{
			name: '',
			selector: 'actions',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: (row, key) => (
				<div className="d-flex align-items-center"
					ref={wrapperRef}>
					<div className='col-5'
						onMouseOver={() => { setRefKay(key + 1); setMore(true); }}
						onMouseLeave={() => { setRefKay(key + 1); setMore(false); }}>

						<span className="e-more"></span>
						{
							more && (key + 1) === refKey ?
								<div className="e-card-transaction" ref={wrapperRef}
									onClick={() => _modalOpen(row)}>
									<span>
										<img src={View} alt="View details" className="me-2" />
										View Details
									</span>
								</div>
								:
								null
						}
					</div>
				</div>
			)
		},
	];

	return (
		<>
			<div className="row position-relative">
				<div className="col-lg-12">
					<h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
						Orders
					</h3>
					<div className="card e-dashboard-card border-0">
						<div className="row pt-2">
							<div className="col-lg-4 col-3">
								<h6 className="e-section-title p-2">All Orders</h6>
							</div>
							<div className="col-lg-3 col-4 align-items-center text-end" >
								<DownloadBtn
									loading={downloadLoader}
									check={check}
									onClick={() => {
										setDownloadLoader(true);
										_downloadOrderList();
									}}
								/>
							</div>
							<div className="col-lg-4 col-4 position-relative">
								<div className="d-flex align-items-center e-bg-search-bg e-border-radius-4">
									<SearchInput
										placeholder="Search with Order ID"
										value={search}
										valueChange={(value) => {
											setSearch(value);
											if (value === "") {
												_getOrderList({
													value: ""
												});
												sessionStorage.removeItem("order_search");
											}
										}}
										onSearch={(value) => {
											_getOrderList({ value: value });
											sessionStorage.setItem("order_search", JSON.stringify(value));

										}}
									/>
									{
										search && search.length ?
											<>
												<button
													className={`${styles.e_search_close} pe-3`}
													onClick={() => {
														setSearch("");
														_getOrderList({
															value: ""
														});
														sessionStorage.removeItem("order_search");
													}}>
												</button>
												<span
													className={`${styles.e_search_submit} `}
													onClick={() => {
														_getOrderList()
													}}>
												</span>

											</>
											:
											null
									}
								</div>

							</div>
							<div className="col-lg-1 col-1">
								<FilterBtn
									// img={Filter}
									className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
									handleClick={() => {
										setFilterDown(!filterDown);
										// setSearch("");
										// if (filterDown === true) {
										// 	setDates({ start: null, end: null });
										// 	setSelectedPayStatus({ label: "All", value: null });
										// 	setSelectedOrderStatus({ label: "All", value: null });
										// 	setType(typeOptions[0])
										// }
									}}
								/>
							</div>

							{/*-------------- Filters  ---------------*/}
							{
								filterDown ?
									<div className="e-filter-wraper e-sort mt-3">
										<div className="row">
											<div className="col-auto pe-0">
												<label className="mb-2">Transactions from - till</label>
												<DateRangePicker
													onDatesChange={(start, end) => {
														setDates({ start: start, end: end });
														if (start === null && end === null) {
															sessionStorage.removeItem("order_range");
														} else {
															sessionStorage.setItem("order_range", JSON.stringify({ start: start, end: end }));
														}
													}}
													start={dates.start}
													end={dates.end}
												/>
											</div>
											<div className="col-lg-2 col-3 px-2">
												<label className="mb-2">Order status</label>
												<SelectBox
													value={selectedOrderStatus}
													options={ORDER_STATUS_OPTIONS}
													selectChange={(temp) => {
														setSelectedOrderStatus(temp);
														if (temp.value === null) {
															sessionStorage.removeItem("order_status");
														} else {
															sessionStorage.setItem("order_status", JSON.stringify(temp));
														}
													}}
													isSearchable={false}
												/>
											</div>
											<div className="col-lg-2 col-3  px-2">
												<label className="mb-2">Payment status</label>
												<SelectBox
													value={selectedPayStatus}
													options={PAYMENT_STATUS_OPTIONS}
													selectChange={(temp) => {
														setSelectedPayStatus(temp);
														if (temp.value === null) {
															sessionStorage.removeItem("payment_status");
														} else {
															sessionStorage.setItem("payment_status", JSON.stringify(temp));
														}
													}}
													isSearchable={false}
												/>
											</div>
											<div className="col-lg-2 col-3  p-0">
												<label className="mb-2">Investment type</label>
												<SelectBox
													value={investmentType}
													options={INVESTMENT_OPTIONS}
													selectChange={(temp) => {
														setInvestmentType(temp);
														if (temp.value === null) {
															sessionStorage.removeItem("investment_type");
														} else {
															sessionStorage.setItem("investment_type", JSON.stringify(temp));
														}
													}}
													isSearchable={false}
												/>
											</div>
											<div className="col-lg-2 col-3 px-2">
												<label className="mb-2">Type</label>
												<SelectBox
													value={type}
													options={TYPE_OPTIONS}
													selectChange={(temp) => {
														setType(temp);
														if (temp.value === null) {
															sessionStorage.removeItem("type")
														} else {
															sessionStorage.setItem("type", JSON.stringify(temp))
														}
													}}
													isSearchable={false}
												/>
											</div>
										</div>
									</div>
									:
									null
							}

							{/*--------------- Order table -----------*/}
							{
								loader == true ?
									<Loader />
									: (orderData.length === 0) ?
										<EmptyNote note="There are no orders to show" />
										:
										<div className="col-lg-12 px-0">
											<DataTable

												pagination={true}
												paginationServer={true}
												paginationTotalRows={totalTableRows}
												perPage={tableRowsPerPage}
												paginationDefaultPage={currentPage}

												columns={ORDER_COLUMNS}
												data={orderData}
												selectableRows={true}
												clearSelectedRows={downloadLoader ? false : true}
												selectRows={(row) => { _handleChange(row) }}
												rowClick={(row) => _modalOpen(row)}
												onChangeRowsPerPage={_handlePerRowsChange}
												onChangePage={_handleTablePageChange}
											/>
										</div>
							}

						</div>
					</div>
				</div>
			</div>

			{/*--------- Order detail modal component ----------------*/}

			<div ref={wrapperRef}>
				<OrderDetail
					data={details}
					order_status={details.order_status}
					payment_status={details.payment_status}
				/>
			</div>

			{/* error toast */}
			{
				error !== null ?
					<Toast
						data={error}
						type="eror"
						id="1"
					/>
					:
					null
			}
			{/* Success toast */}
			{
				success !== null ?
					<Toast data={success}
						type="success"
						id="2" />
					:
					null
			}
		</>
	);
}

export default Orders;

