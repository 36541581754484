/*
 *   File : trigger-rebalance.js
 *   Author : https://evoqins.com
 *   Description : Rebalance trigger modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useState } from "react";

// Components
import { _closeModal } from "../../Helper/function";
import { PrimaryBtn, SecondaryBtn } from "../Cta";

// Assets
import FundLogo from "../../Assets/Images/General/fund-navi-logo.png";
import ArrowDown from "../../Assets/Images/Kyc/left-arrow.svg";
import { TextArea } from "../Input";
import DataTable from "react-data-table-component";



const REBALANCE_USERS = [
    {
        name: "Ralph Edwards",
        email: "ralphedwards@gmail.com",
        joined_on: '24 Jun 2020',
        last_rebalanced_on: '24 Jun 2020',
    },
    {
        name: "Ralph Edwards",
        email: "ralphedwards@gmail.com",
        joined_on: '24 Jun 2020',
        last_rebalanced_on: '24 Jun 2020',
    },
    {
        name: "Ralph Edwards",
        email: "ralphedwards@gmail.com",
        joined_on: '24 Jun 2020',
        last_rebalanced_on: '24 Jun 2020',
    },
    {
        name: "Ralph Edwards",
        email: "ralphedwards@gmail.com",
        joined_on: '24 Jun 2020',
        last_rebalanced_on: '24 Jun 2020',
    },
    {
        name: "Ralph Edwards",
        email: "ralphedwards@gmail.com",
        joined_on: '24 Jun 2020',
        last_rebalanced_on: '24 Jun 2020',
    },
    {
        name: "Ralph Edwards",
        email: "ralphedwards@gmail.com",
        joined_on: '24 Jun 2020',
        last_rebalanced_on: '24 Jun 2020',
    },
    {
        name: "Ralph Edwards",
        email: "ralphedwards@gmail.com",
        joined_on: '24 Jun 2020',
        last_rebalanced_on: '24 Jun 2020',
    },
];

function TriggerRebalanceModal(props) {

    const [showPreview, setShowPreview] = useState(false);
    const [rebalanceReason, setRebalanceReason] = useState("");
    const [rebalanceReasonErr, setRebalanceReasonErr] = useState("");


    // rebalance users table columns
    const REBALANCE_USERS_COLUMN = [
        {
            width: "30px",
            cell: (row, index) => (
                <span className="e-text-dark-silver">
                    {index + 1}.
                </span>
            ),
            style: {
                paddingLeft: "0px",
            },
        },
        {
            name: 'Name',
            selector: row => row["name"].toLowerCase(),
            sortable: true,
            minWidth: "140px",
            cell: row => (
                <span >
                    {row["name"]}
                </span>
            ),
        },
        {
            name: 'Email',
            selector: row => row["email"] || "--",
            minWidth: "200px",
            cell: row => (
                <span data-tag="allowRowEvents">{row["email"] || "--"}</span>
            ),
        },
        {
            name: 'Joined on',
            selector: row => row.joined_on,
            // sortable: true,
            left: true,
            width: "120px",
            // defaultSortAsc: false,
            cell: row => row.joined_on || "--"
        },
        {
            name: 'Last rebalanced on',
            selector: row => row.last_rebalanced_on,
            // sortable: true,
            left: true,
            width: "160px",
            // defaultSortAsc: false,
            cell: row => row.last_rebalanced_on || "--"
        },
    ];

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onModalClose);
    }, []);

    // handle rebalance value change
    const _handleRebalanceReasonChange = (value) => {
        setRebalanceReason(value);
        setRebalanceReasonErr("");
    };

    // handle show preview details
    const _handleShowPreview = () => {
        if (rebalanceReason) {
            setShowPreview(true);
        } else {
            setRebalanceReasonErr("Please enter the reason");
        }
    };

    return (
        <div className="modal fade e-manage-modal"
            data-bs-backdrop="static"
            id={"trigger-rebalance-modal"}
            tabIndex="-1"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg px-lg-5">
                <div className="modal-content e-border-radius-8 e-modal-box-shadow mx-lg-5">
                    <div className="modal-header justify-content-between pb-3 pt-2  px-2 border-0">
                        <h6 className='e-text-charleston-green e-font-16  e-font-manrope e-font-weight-700 line-height-20px mb-0'>
                            Trigger Rebalance
                        </h6>
                        <button type="button"
                            id={`trigger-rebalance-modal`}
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onModalClose}></button>
                    </div>
                    <div className="modal-body pb-2 px-2 pt-0">
                        {
                            showPreview === false ?
                                <div className="mt-3">
                                    <p className='e-text-charleston-green e-font-16  e-font-manrope e-font-weight-600 line-height-20px mb-3'>
                                        Why is rebalance required now
                                    </p>
                                    <TextArea value={rebalanceReason}
                                        rows={10}
                                        placeholder={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
                                        error={rebalanceReasonErr}
                                        valueChange={_handleRebalanceReasonChange} />

                                    {/* Action buttons */}
                                    <div className='row w-100 mt-1'>
                                        <div className='col-4 col-lg-3'>
                                            <SecondaryBtn name="Cancel"
                                                className="e-text-tab-color w-100 h-40 d-flex justify-content-center align-items-center e-border-1px-tab-color"
                                                cancel="modal"
                                                handleClick={props.onModalClose} />
                                        </div>
                                        <div className='col-4 col-lg-3'>
                                            <PrimaryBtn name="Submit"
                                                className="w-100 h-40 d-flex justify-content-center align-items-center"
                                                handleClick={_handleShowPreview} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="mt-3">
                                    <p className="e-text-charleston-green e-font-16  e-font-manrope e-font-weight-600 line-height-20px mb-3">
                                        Rebalance allocation changes
                                    </p>
                                    <div className="d-flex flex-column gap-3 p-3 e-border-radius-8 e-border-1px-platinum-gray">
                                        <div className="d-flex gap-3 align-items-center">
                                            <img src={FundLogo}
                                                width={64}
                                                alt=""
                                                className="e-border-radius-16px h-auto" />
                                            <p className="e-text-charleston-green e-font-18  e-font-manrope e-font-weight-500 line-height-24px mb-0">
                                                Navi Flexi Cap Fund Direct Growth
                                            </p>
                                            <span className="ms-auto e-text-charleston-green e-font-18  e-font-manrope e-font-weight-500 line-height-24px">
                                                30%
                                            </span>
                                        </div>

                                        <img src={ArrowDown}
                                            width={24}
                                            className="d-block text-center mx-auto rotate-min-90deg"
                                            draggable={false}
                                            alt='arrow' />

                                        <div className="d-flex gap-3 align-items-center">
                                            <img src={FundLogo}
                                                width={64}
                                                alt=""
                                                className="e-border-radius-16px h-auto" />
                                            <p className="e-text-charleston-green e-font-18  e-font-manrope e-font-weight-500 line-height-24px mb-0">
                                                Navi Flexi Cap Fund Direct Growth
                                            </p>
                                            <span className="ms-auto e-text-charleston-green e-font-18  e-font-manrope e-font-weight-500 line-height-24px">
                                                50%
                                            </span>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <p className="e-text-charleston-green e-font-16  e-font-manrope e-font-weight-600 line-height-20px mb-3">
                                            Rebalance reflecting users
                                        </p>
                                        <div id="rebalance-user-table">
                                            <DataTable
                                                striped={false}
                                                pagination={false}
                                                columns={REBALANCE_USERS_COLUMN}
                                                data={REBALANCE_USERS}
                                                selectableRows={false}
                                                highlightOnHover={false}
                                                customStyles={{
                                                    rows: {
                                                        style: {
                                                            border: "none !important",
                                                            cursor: "default",
                                                            backgroundColor: "transparent !important",
                                                            "&:hover": {
                                                                backgroundColor: "transparent !important",
                                                            },
                                                        },
                                                    },
                                                    headCells: {
                                                        style: {
                                                            paddingLeft: "0px",
                                                        },
                                                    },
                                                    cells: {
                                                        style: {
                                                            paddingLeft: "0px",
                                                        },
                                                    },
                                                }}
                                            />

                                            <div className='row w-100 mt-4'>
                                                <div className='col-4 col-lg-3'>
                                                    <SecondaryBtn name="Cancel"
                                                        className="e-text-tab-color w-100 h-40 d-flex justify-content-center align-items-center e-border-1px-tab-color"
                                                        cancel="modal"
                                                        handleClick={props.onModalClose} />
                                                </div>
                                                <div className='col-4 col-lg-3'>
                                                    <a href={`mailto:${"vijay@evoqins.com"}`}>
                                                        <PrimaryBtn name="Send mail"
                                                            className="w-100 h-40 d-flex justify-content-center align-items-center" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TriggerRebalanceModal;