/*
 *   File : verify-switch-otp.js
 *   Author : https://evoqins.com
 *   Description : verify fund switch otp modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

//custom component
import { PrimaryBtn, SecondaryBtn } from '../Cta';
import { OTPInput } from '../Form-Elements';
import service from '../../Services/service';
import { _closeModal } from '../../Helper/function';
import { _sendOTP } from '../../Helper';

const VerifySwitchOTPModal = (props) => {

    let switch_data = props.data;
    let user_data = switch_data.user_data;

    const inputRef = useRef(null);
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    const [focusedInput, setFocusedInput] = useState(0);
    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);
    const [disableResend, setDisableResend] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Focusing OTP input - when load
    useEffect(() => {
        setTimeout(() => {
            setFocusedInput(2);
        }, 500);
    }, []);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);


    //for timer
    useEffect(() => {
        if (timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [timer]);

    //handle confirm pin
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    // handle OTP resend
    const _handleOTPResend = () => {

        setDisableResend(true);

        // API CALL from helper function - OTP
        let param = {
            type: "Switch",
            id: user_data.id,
        };

        _sendOTP(param).then(status => {
            if (status.status_code === 200) {
                setFocusedInput(2);
                setTimer(30);
                setOtpValue("");
                toast.dismiss();
                toast.success("OTP sent successfully");
            } else {
                toast.dismiss();
                toast.error(status.data.message, {
                    className: "e-toast",

                });
            };

            setDisableResend(false);

        });
    };

    // handle confirm switch
    const _handleConfirmSwitch = () => {

        if (otpValue.length === 0) {
            setOtpError("Please enter a valid OTP.");
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
        }
        else {
            setLoading(true);
            // API
            _createSwitchOrder();
        }
    };

    // API CALL //////////////////////////
    // Switch fund flow APIs
    const _createSwitchOrder = () => {

        let is_post = true;
        let url = "/goal/mf/create-switch";
        let data = {
            "customer_id": user_data.id,
            "switch_out_mf_id": switch_data.switch_out_mf_id,
            "switch_in_mf_id": switch_data.switch_in_mf_id,
            "switch_all": switch_data.switch_all,
            "folio_number": switch_data.folio_number,
            "otp": otpValue,
            "units": switch_data.units,
            "amount": switch_data.amount,
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Fund switch order created successfully");
                const close_btn = document.querySelector("#switch-otp-modal-close");
                close_btn.click();
                props.handleOrderSuccess();
            } else {
                setErrorMessage(response.data.message);
            };
            setLoading(false);
        });

    };


    return (
        <div className={`modal fade`}
            id="switch-otp-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="verifySwitchOTPModalLabel"
            aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered`}>
                <div className="modal-content e-border-radius-8 p-0">
                    <div className="modal-header justify-content-space-between pt-3 px-3 pb-0 border-0">
                        <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                            {errorMessage ? "Switch failed" : "Verify OTP"}
                        </h6>
                        <button type="button"
                            id="switch-otp-modal-close"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onCloseModal}></button>
                    </div>

                    <div className="modal-body pb-3 px-3 ">

                        {
                            !errorMessage ?
                                <>
                                    {/* otp section */}
                                    <div className='p-3 e-border-radius-16 e-bg-cultured '>
                                        <p className='mb-0 e-text-onyx e-font-16 e-font-manrope e-font-weight-400 line-height-28px'>
                                            An OTP has been sent to registered
                                            {user_data.country_code === "+91" ? "  mobile number " : " email "}
                                            <span className='w-100 d-block'>
                                                {
                                                    user_data.country_code === "+91" ?
                                                        <span className='e-text-primary e-font-weight-500'>
                                                            {user_data.country_code}-{user_data.phone}
                                                        </span>
                                                        :
                                                        <span className='e-text-primary e-font-weight-500'>
                                                            {user_data.email}
                                                        </span>
                                                }
                                            </span>
                                        </p>
                                        <div className='pt-3'>
                                            <label className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px">
                                                Enter your OTP*
                                            </label>
                                            <div className='h-64'>
                                                <OTPInput ref={inputRef}
                                                    id="otp"
                                                    value={otpValue}
                                                    error={otpError}
                                                    spellCheck="false"
                                                    focused_input={focusedInput}
                                                    onFocus={() => setFocusedInput(2)}
                                                    onBlur={() => setFocusedInput(0)}
                                                    onChange={_handleChangeOTP} />
                                            </div>

                                            <div className='d-flex pt-2 align-items-center'>
                                                <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 bg-transparent e-text-primary e-font-12 e-font-manrope e-font-weight-500 line-height-26px`}
                                                    disabled={disableResend}
                                                    onClick={_handleOTPResend} >
                                                    Re-send OTP
                                                </button>
                                                {timer !== 0 &&
                                                    <p className='mb-0 e-text-chinese-black e-font-12 e-font-manrope e-font-weight-600 line-height-26px'>
                                                        in {timer} {timer == 1 ? "second" : "seconds"}
                                                    </p>
                                                }
                                            </div>
                                        </div>

                                        <p className='mb-0 pt-3 e-text-light-grey e-font-12 e-font-manrope e-font-weight-400 line-height-18px'>
                                            *In accordance with regulatory, 2FA verification is required in order to create an order.
                                        </p>


                                    </div>
                                    <div className='mt-4'>
                                        <PrimaryBtn name={"Confirm Switch"}
                                            loading={loading}
                                            disabled={loading}
                                            className="mt-2 w-100 d-flex align-items-center justify-content-center h-40"
                                            handleClick={_handleConfirmSwitch} />
                                    </div>
                                </>
                                :
                                <div>
                                    <p className='text-center mb-0 e-text-onyx e-font-16 e-font-manrope e-font-weight-400 line-height-28px py-4'>
                                        {errorMessage}
                                    </p>

                                    <div className='row mt-3 justify-content-end'>
                                        <div className='col-4'>
                                            <SecondaryBtn name="Back"
                                                className="w-100 h-100 py-2"
                                                // handleClick={props.onSwitchFailed} />
                                                handleClick={() => setErrorMessage("")} />
                                        </div>
                                        <div className='col-4'>
                                            <PrimaryBtn name="Close"
                                                className="w-100 h-40 d-flex justify-content-center"
                                                handleClick={props.onCloseModal} />
                                        </div>
                                    </div>
                                </div>
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(VerifySwitchOTPModal);