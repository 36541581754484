/*
 *   File : configure-goal.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : goal creation page
 *   Integrations :23-04-2024
 *   Version : v1.0
 *   Created : 23-04-2024
 */

import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";

// Components
import { DataTable, FundsTab } from "../../Components/DataFormating";
import SelectBox from "../../Components/Dropdown/select-box";
import { PrimaryBtn, Toggle } from "../../Components/Cta";
import { CheckBox, CustomAmountInput, CustomSearchSelect, CustomSlider, CustomTextInput, RadioGroup } from "../../Components/Form-Elements";
import { BasketInvestModal, GoalInvestModal, MFInvestModal, OrderStatusModal, SetRiskScoreModal, VerifySwitchOTPModal } from "../../Components/Modal";
import { LifeGoalCard, RetirementGoalCard } from "../../Components/Cards";
import { Input, SearchInput } from "../../Components/Input";
import { _basketSummary, _goalSummary, _mfSummary, _sendOTP, _setGoal } from "../../Helper";
import service from "../../Services/service";
import { Loader } from "../../Components/Loader";

/*import styles*/
import styles from '../../Styles/Components/search-input.module.scss';


// Assets
import Edit from '../../Assets/Images/Cta/edit.svg';
import SearchFunds from '../../Assets/Images/DataFormating/search-funds.svg'
import Delete from '../../Assets/Images/Cta/delete-topic.svg'
import { EmptyNote } from "../../Components/Note";

// Investment select 
const INVESTMENT_TYPE = [
    {
        id: 1,
        display_name: "Goals"
    },
    {
        id: 2,
        display_name: "Basket"
    },
    {
        id: 3,
        display_name: "Fund"
    },
    {
        id: 4,
        display_name: "Switch"
    },
];

const SWITCH_TYPE = [
    {
        id: "AMOUNT",
        display_name: "Amount"
    },
    {
        id: "UNITS",
        display_name: "Unit"
    },
];


function ConfigureGoal() {

    const available_investment_types = useRef({}); // Fund => SIP , Lumpsum

    // Step 1 => Search user
    // Step 2 => Selected user detail
    // Step 3 => Select investment type
    // Step 4 => Configuring goal details
    // Step 5 => Initiate order
    const [currentStep, setCurrentStep] = useState(1);

    const [tabId, setTabId] = useState(1);
    const [selectedInvestOptions, setSelectedInvestOptions] = useState([]);
    const [goalListData, setGoalListData] = useState([]);

    const [selectedUser, setSelectedUser] = useState([]);
    const [selectedGoal, setSelectedGoal] = useState([]);

    const [search, setSearch] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [loadingResults, setLoadingResults] = useState(false);
    const [showSearch, setShowSearch] = useState(true);

    const [calculatedCorpusData, setCalculatedCorpusData] = useState("");
    const [saveSetGoalData, setSaveSetGoalData] = useState("");
    const [goalLoader, setGoalLoader] = useState(false);
    const [orderInitiateLoader, setOrderInitiateLoader] = useState(false);
    const [riskScore, setRiskScore] = useState("");
    const [riskScoreData, setRiskScoreData] = useState([]);
    const [showRiskScoreSelect, setShowRiskScoreSelect] = useState(false);
    const [title, setTitle] = useState("");
    const [tenureMin, setTenureMin] = useState(0);
    const [tenureMax, setTenureMax] = useState(0);
    const [fundSearch, setFundSearch] = useState("");
    const [fundList, setFundList] = useState([]);
    const [fundLoader, setFundLoader] = useState(false);
    const [selectedFund, setSelectedFund] = useState("");
    const [activeTabBasket, setActiveTabBasket] = useState(1);
    const [sipBasket, setSipBasket] = useState(0);
    const [sipBasketErr, setSipBasketErr] = useState("");
    const [lumpsumBasket, setLumpsumBasket] = useState(0);
    const [lumpsumBasketErr, setLumpsumBasketErr] = useState("");
    const [basketData, setBasketData] = useState("");

    // Goal Summary
    const [goalSummary, setGoalSummary] = useState([]);
    const [monthlySip, setMonthlySip] = useState(0);
    const [lumpsumAmt, setLumpsumAmt] = useState(0);
    const [tenure, setTenure] = useState(8);
    const [monthlySipErr, setMonthlySipErr] = useState("");
    const [lumpsumAmtErr, setLumpsumAmtErr] = useState("");
    const [investmentSelect, setInvestmentSelect] = useState(1);
    const [investmentBasketTabs, setInvestmentBasketTabs] = useState([""]);

    // Summary
    const [investSummary, setInvestSummary] = useState([]);
    const [showGoalInvestModal, setShowGoalInvestModal] = useState(false);
    const [showBasketInvestModal, setShowBasketInvestModal] = useState(false);
    const [showMFInvestModal, setShowMFInvestModal] = useState(false);
    const [showOrderStatusModal, setShowOrderStatusModal] = useState(false);
    const [paymentURL, setPaymentURL] = useState("");
    const [mandateURL, setMandateURL] = useState("");


    // Switch holding
    const [userHoldingsLoader, setUserHoldingsLoader] = useState(true);
    const [userHoldings, setUserHoldings] = useState([]);
    const [switchHoldings, setSwitchHoldings] = useState([]);
    const [selectedSwitchHoldingsFrom, setSelectedSwitchHoldingsFrom] = useState(null);
    const [selectedFolio, setSelectedFolio] = useState(null);
    const [folioData, setFolioData] = useState([]);
    const [selectedSwitchHoldingsTo, setSelectedSwitchHoldingsTo] = useState(null);
    const [selectedSwitchType, setSelectedSwitchType] = useState(SWITCH_TYPE[0].id);
    const [switchAmount, setSwitchAmount] = useState(0);
    const [switchAmountError, setSwitchAmountError] = useState("");
    const [switchUnit, setSwitchUnit] = useState(0);
    const [switchUnitError, setSwitchUnitError] = useState("");
    const [switchAll, setSwitchAll] = useState(false);
    const [switchLoader, setSwitchLoader] = useState(false);
    const [showSwitchOTPModal, setShowSwitchOTPModal] = useState(false);

    const initialRender = useRef(true);

    // data table column 
    const FUND_COLUMNS = [
        {
            name: 'Fund Name',
            selector: row => row.scheme_name,
            width: "550px",
            cell: row => (
                <span className="ps-3 e-text-chinese-black e-font-manrope e-font-weight-400  e-font-14 line-height-18px">
                    {row.scheme_name}
                </span>
            )
        },
        {
            name: 'Allocation',
            selector: row => row.percentage + "%",
            width: "150px",
        },
        {
            name: 'Amount',
            // selector: row => _lumpsumCalculation(row),
            cell: row => (
                <div className="e-cell-lumpsum cursor-default">
                    {/* ₹{_lumpsumCalculation(row).toLocaleString("en-IN")} */}
                    ₹{row.amount.toLocaleString("en-IN")}
                </div>
            )
        },
    ];

    useEffect(() => {
        if (investmentSelect == 1 || investmentSelect == 2) {
            _getGoalList();
        }
    }, [investmentSelect]);

    useEffect(() => {
        if (fundSearch.length > 2) {
            setFundLoader(true);
            _searchFunds();
        };
    }, [fundSearch]);

    useEffect(() => {
        if (search.length > 2) {
            setLoadingResults(true);
            _searchUser();
        };
    }, [search]);

    useEffect(() => {
        if (selectedFund) {
            _getFundDetail();
        };
    }, [selectedFund]);


    useEffect(() => {

        if (showRiskScoreSelect === true) {
            new Modal(document.querySelector("#risk-score-modal")).show();
        };

    }, [showRiskScoreSelect]);

    useEffect(() => {

        if (showGoalInvestModal === true) {
            const goal_invest_modal = new Modal(document.querySelector("#invest-goal-modal"));
            goal_invest_modal.show();
        };

    }, [showGoalInvestModal]);

    useEffect(() => {

        if (showOrderStatusModal === true) {
            const order_stauts_modal = new Modal(document.querySelector("#order-status-modal"));
            order_stauts_modal.show();
        };

    }, [showOrderStatusModal]);

    useEffect(() => {

        if (showBasketInvestModal === true) {
            const basket_invest_modal = new Modal(document.querySelector("#invest-basket-modal"));
            basket_invest_modal.show();
        };

    }, [showBasketInvestModal]);

    useEffect(() => {

        if (showMFInvestModal === true) {
            const basket_invest_modal = new Modal(document.querySelector("#invest-mf-modal"));
            basket_invest_modal.show();
        };

    }, [showMFInvestModal]);

    useEffect(() => {
        if (switchAll === true) {
            if (selectedSwitchType === "AMOUNT") {
                setSwitchAmount(selectedFolio.amount);
            } else {
                setSwitchUnit(selectedFolio.units);
            }
        }
    }, [switchAll]);

    useEffect(() => {

        if (showSwitchOTPModal === true) {
            const switch_modal = new Modal(document.querySelector("#switch-otp-modal"));
            switch_modal.show();
        };

    }, [showSwitchOTPModal]);


    // lumpsum calculation 
    // const _lumpsumCalculation = (row) => {
    //     let calculated_lumpsum = Number(lumpsumAmt) * row.percentage / 100;
    //     return calculated_lumpsum;
    // };

    const _handleUserSearch = (search) => {
        setSearch(search);
    };

    const _handleSelectUser = (selected_user) => {
        setSelectedUser(selected_user);
        setCurrentStep(2);
        if (selected_user.risk_score === null) {
            _getRiskScore();
        };
    };

    const _handleShowRiskScore = () => {
        setShowRiskScoreSelect(true);
    };

    const _handleSetRiskScore = (risk_score) => {
        setRiskScore(risk_score);
        setSelectedGoal([]);
        setCurrentStep(1);
    };

    const _handleShowGoalSelect = () => {

        if (selectedUser.risk_score === null && riskScore === "") {
            toast.dismiss();
            toast.error("Please set risk score to continue");
            return;
        };

        setShowSearch(false);
        setCurrentStep(3);


    };

    //handle investment select
    const _handleInvestmentSelect = (id) => {
        setInvestmentSelect(id);
        setSelectedGoal([]);
        setCurrentStep(3);
        setCalculatedCorpusData("");
        setFundSearch("");
        setFundList([]);
        setSelectedFund("");
        initialRender.current = true;
        _handleClearSwitchData();

        // Switch holdings flow
        if (id === 4) {
            setUserHoldingsLoader(true);
            // API call
            _getUserHoldings();
        }
    };

    const _handleChangeSelectedFund = () => {
        setSelectedFund("");
    };

    const _handleGoalSelect = (selected) => {

        if (selected.is_goal == false) {
            _getBasketDetail(selected.id);
            setSipBasketErr("");
            setLumpsumBasketErr("");
        } else {
            setCurrentStep(4);
        };

        setSelectedGoal(selected);

        switch (selected.value) {
            case "Pension": {
                setTitle("Target amount");
                setTenureMin(1);
                setTenureMax(selected.meta_data.retirement_age.max - selected.meta_data.current_age.min_age);
                break;
            }
            case "Vacation Fund": {
                setTitle("Target Amount");
                setTenureMin(selected.meta_data.tenure.min);
                setTenureMax(selected.meta_data.tenure.max);
                break;
            }
            case "Special Tuition Fund": {
                setTitle("Cost of the education");
                setTenureMin(selected.meta_data.tenure.min);
                setTenureMax(selected.meta_data.tenure.max);
                break;
            }
            case "Post Graduation": {
                setTitle("Target amount");
                setTenureMin(selected.meta_data.from.min);
                setTenureMax(selected.meta_data.from.max);
                break;
            }
            case "Emergency Fund": {
                setTitle("Target amount");
                setTenureMin(selected.meta_data.tenure.min);
                setTenureMax(selected.meta_data.tenure.max);
                break;
            }
            default: return;
        };

    };

    const _handleMonthlySipChange = (amt) => {
        setMonthlySip(amt);
        setMonthlySipErr("");

        let updated_save_goal_data = {
            ...saveSetGoalData,
            "sip": amt
        };

        setSaveSetGoalData(updated_save_goal_data);
    };

    const _handleLumpsumAmtChange = (amt) => {
        setLumpsumAmt(amt);
        setLumpsumAmtErr("");

        let updated_save_goal_data = {
            ...saveSetGoalData,
            "lumpsum": amt
        };

        setSaveSetGoalData(updated_save_goal_data);
    };

    const _handleTenureChange = (tenure) => {
        setTenure(tenure);
        let updated_save_goal_data = {
            ...saveSetGoalData,
            "tenure": tenure
        };
        setSaveSetGoalData(updated_save_goal_data);
    };

    const _handleClearUser = () => {
        // setShowGoalDetail(false);
        // setShowGoalSummary(false);
        // setSelectedUser([]);
        // setSelectedGoal([]);
        // setSearchResults([]);
    };

    const _handleClearFundSearch = () => {
        setFundSearch("");
        // setFundList([]);
    };

    const _handleEdit = () => {
        setShowSearch(true);
        setCurrentStep(1);
        setSelectedGoal([]);
        setCalculatedCorpusData("");
        setInvestSummary([]);
        setSaveSetGoalData("");
        setMonthlySip(0);
        setLumpsumAmt(0);
        setFundSearch("");
        setFundList([]);
        setSelectedFund("");
        setInvestmentSelect(1);
        setSipBasket(0);
        setLumpsumBasket(0);
        setSipBasketErr("");
        setLumpsumBasketErr("");

        // Switch data
        _handleClearSwitchData();
        initialRender.current = true;
    };

    const _handleCloseInvestModal = () => {
        // Closing modal
        var button = document.getElementById("basket-invest-modal-close");
        button.click();
        setShowBasketInvestModal(false);
    };

    const _handleCloseMFInvestModal = (type) => {
        // Closing modal
        var button = document.getElementById("mf-invest-modal-close");
        button.click();
        setShowMFInvestModal(false);
    };

    const _handleOnAmountInputChange = (amt) => {
        if (activeTabBasket == 1) {
            setSipBasket(amt);
            setSipBasketErr("");
        } else {
            setLumpsumBasket(amt);
            setLumpsumBasketErr("");
        }
    };

    const _handleSetGoal = (data) => {

        setGoalLoader(true);
        setTenure(data.tenure);

        let param = {
            "customer_id": selectedUser.id,
            "is_inflation_enabled": data.is_inflation_enabled,
            "goal_id": selectedGoal.id,
            "tenure": data.tenure,
            "target": data.target,
            "lumpsum": data.lumpsum,
            "step_sip": data.step_sip,
            "sip": monthlySip,
            "lumpsum_select": data.lumpsum_select,
        };


        // API - save goal details
        _setGoal(param).then(response => {
            if (response.status_code === 200) {
                setGoalSummary(response.data);
                setMonthlySip(response.data.sip);
                setLumpsumAmt(response.data.lumpsum);
                setTenure(response.data.tenure);
                setCurrentStep(5);

                // Saving goal data
                param = {
                    ...param,
                    "sip": response.data.sip,
                    "lumpsum": response.data.lumpsum,
                    "tenure": response.data.tenure
                };

                setSaveSetGoalData(param);

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            };

            setGoalLoader(false);
        });

    };

    const _handleInvestLumpsumChange = () => {
        if (currentStep === 5) {
            setCurrentStep(4);
            setMonthlySip(0);
        };
    }

    const _handleInitiateGoalInvest = () => {
        let validation = true;
        let err_list = [];
        if (saveSetGoalData.lumpsum_select && Number(lumpsumAmt) < 1) {
            setLumpsumAmtErr("Lumpsum amount required");
            validation = false;
            err_list.push("lumpsum-amt");
        }
        if (Number(monthlySip < 1)) {
            setMonthlySipErr("Sip amount required");
            validation = false;
            err_list.push("monthly-sip");
        }

        // Scrolling to first error
        if (err_list.length !== 0) {
            let first_err_id = err_list[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

        if (validation === true) {
            setOrderInitiateLoader(true);
            _setGoal(saveSetGoalData).then(response => {
                if (response.status_code === 200) {
                    setOrderInitiateLoader(false);
                    _getGoalSummary();
                } else {
                    toast.dismiss();
                    toast.error(response.data.message);
                }
                setOrderInitiateLoader(false);
            })
        }
    };

    const _handleInitiateBasketInvest = () => {

        if (activeTabBasket === 1 && sipBasket === 0) {
            setSipBasketErr("Please provide sip amount");
            return;
        } else if (activeTabBasket === 2 && lumpsumBasket === 0) {
            setLumpsumBasketErr("Please provide lumpsum amount");
            return;
        };

        // API Basket summary
        setOrderInitiateLoader(true);
        _getBasketSummary();
    };

    const _handleBasketInvestSelect = (tab_id) => {
        setActiveTabBasket(tab_id);
        setSipBasketErr("");
        setLumpsumAmtErr("");
    };

    const _handleInitiateMFInvest = () => {

        if (available_investment_types.current.available_for_purchase) {
            // API Basket summary
            setOrderInitiateLoader(true);
            _getMFSummary();
        } else {
            toast.dismiss();
            toast.error("This fund is not available for purchase");
        };

    };

    const _handleCalculateCorpus = (corpus_data) => {
        _calculateRetirementCorpus(corpus_data);
    };

    const _handleInvestStatus = (status, url) => {

        if (status === "PAYMENT_URL_CREATED") {
            setPaymentURL(url);
        } else if (status === "INITIATE_MANDATE") {
            setMandateURL(url);
        } else {
            setPaymentURL(null);
            setMandateURL(null);
        };

        setShowOrderStatusModal(true);
        setShowMFInvestModal(false);
        setShowGoalInvestModal(false);
        setShowBasketInvestModal(false);

    };

    const _handleSelectHoldingFromToSwitch = (selected) => {
        setSelectedSwitchHoldingsFrom(selected);

        // Update folio data
        if (selected.switch_data.folio_data.length !== 0) {
            let folio_data = selected.switch_data.folio_data.map((item) => {
                return {
                    ...item,
                    "label": `${item.folio_number} (Amount :₹${item.amount.toLocaleString("en-IN")},   Units : ${item.units})`,
                    "value": item.folio_number
                }
            });

            setFolioData(folio_data);
        }

        setSelectedFolio(null);
        setSelectedSwitchHoldingsTo(null);
        setSwitchAmount(0);
        setSwitchAmountError("");
        setSwitchUnit(0);
        setSwitchUnitError("");
        setSelectedSwitchType("AMOUNT");
        setSwitchAll(false);

        // Temp place
        _getSwitchFunds(selected.switch_data.mf_id);

        // Clearing folio when user selects new fund
        // setSelectedFolio(null);
    };

    const _handleSelectFolio = (selected) => {
        setSelectedFolio(selected);
        setSwitchAmount(0);
        setSwitchAmountError("");
        setSwitchUnit(0);
        setSwitchUnitError("");
        setSelectedSwitchType("AMOUNT");
        setSwitchAll(false);
    };

    const _handleSelectHoldingToSwitch = (selected) => {
        setSelectedSwitchHoldingsTo(selected);
    };

    const _handleSwitchTypeSelect = (type) => {
        setSelectedSwitchType(type);
        setSwitchAll(false);
        if (type === "AMOUNT") {
            setSwitchUnitError("");
        } else {
            setSwitchAmountError("");
        }
    };

    const _handleSwitchAmountChange = (value) => {
        setSwitchAmount(value);
        setSwitchAmountError("");
    };

    const _handleSwitchUnitChange = (value) => {
        setSwitchUnit(value);
        setSwitchUnitError("");
    };

    const _handleSwitchAll = () => {
        setSwitchAll(prev_state => !prev_state);
        setSwitchAmountError("");
        setSwitchUnitError("");
    };

    const _handleSuccessSwitchOrder = () => {
        // Clearing all data after success
        setShowSwitchOTPModal(false);

        _handleClearSwitchData();

        // API - Updating holdings if any holdings completely switched 
        _getUserHoldings();
    };

    const _handleCloseSwitchModal = () => {
        setShowSwitchOTPModal(false);
    };

    // Clearing all switch data
    const _handleClearSwitchData = () => {
        setSelectedSwitchHoldingsFrom(null);
        setSelectedFolio(null);
        setSelectedSwitchHoldingsTo(null);
        setSwitchAmount(0);
        setSwitchAmountError("");
        setSwitchUnit(0);
        setSwitchUnitError("");
        setSelectedSwitchType("AMOUNT");
        setSwitchAll(false);
    };

    const _handleConfirmSwitch = () => {

        if (switchAll === false) {
            if (selectedSwitchType === "AMOUNT") {
                if (switchAmount === 0) {
                    setSwitchAmountError("Please enter amount to proceed");
                    return;
                } else if (switchAmount < selectedSwitchHoldingsTo.min_switch_amount && switchAmount < selectedSwitchHoldingsFrom.switch_data.min_switch_amount) {
                    setSwitchAmountError(`Minimum switch out is ₹${selectedSwitchHoldingsFrom.switch_data.min_switch_amount.toLocaleString("en-IN")}.  Minimum switch in is ₹${selectedSwitchHoldingsTo.min_switch_amount.toLocaleString("en-IN")}`);
                    return;
                } else if (switchAmount < selectedSwitchHoldingsFrom.switch_data.min_switch_amount) {
                    setSwitchAmountError(`Minimum switch out is ₹${selectedSwitchHoldingsFrom.switch_data.min_switch_amount.toLocaleString("en-IN")}`);
                    return;
                } else if (switchAmount < selectedSwitchHoldingsTo.min_switch_amount) {
                    setSwitchAmountError(`Minimum switch in is ₹${selectedSwitchHoldingsTo.min_switch_amount.toLocaleString("en-IN")}`);
                    return;
                } else if (switchAmount > selectedFolio.amount) {
                    setSwitchAmountError(`Maximum switch is ₹${selectedFolio.amount.toLocaleString("en-IN")}`);
                    return;
                }

            } else {
                if (switchUnit === 0) {
                    setSwitchUnitError("Please enter unit to proceed");
                    return;
                } else if (switchUnit < 1) {
                    setSwitchUnitError("Minimum switch unit is 1");
                    return;
                } else if (switchUnit > selectedFolio.units) {
                    setSwitchUnitError("Maximum switch unit is " + selectedFolio.units);
                    return;
                }
            }
        };

        setSwitchLoader(true);

        // API CALL from helper function - OTP
        let param = {
            type: "Switch",
            id: selectedUser.id,
        };

        _sendOTP(param).then(status => {
            if (status.status_code === 200) {
                setShowSwitchOTPModal(true);
            } else {
                toast.dismiss();
                toast.error(status.data.message);
            };
            setSwitchLoader(false);
        });
    };


    //==================================== //
    //=========== API CAllS ============== //


    // API - Search user
    const _searchUser = () => {
        let is_post = true;
        let url = "/goal/search-user";
        let data = {
            "query": search
        }

        service(is_post, url, data).then((response => {
            if (response.status_code === 200) {

                // Adding label prop for search options 
                let search_results = response.data.map(item => {
                    return {
                        ...item,
                        label: `${item.name}(${item.customer_id})`,
                        value: item.pan,
                        id: item.id,
                    }
                });
                setSearchResults(search_results);

            } else {
                setSearchResults([]);
            };

            setLoadingResults(false);

        }));
    };

    // API - Search funds
    const _searchFunds = () => {

        const is_post = true;
        const url = '/fund/scheme/list'
        const data = {
            scheme_name: fundSearch,
            page_num: 1,
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setFundList(response.data.funds);
                setSelectedFund("");
            } else {
                setFundList([]);
            };
            initialRender.current = false;
            setFundLoader(false);
        });
    };

    // API - Get goal list
    const _getGoalList = () => {
        let is_post = true;
        let url = "/goal/list";
        let data = {
            "is_goal": investmentSelect == 1 ? true : false,
        };

        service(is_post, url, data).then((response => {
            if (response.status_code === 200) {

                setGoalListData(response.data);

                let goal_list = response.data.map((item) => {
                    return {
                        ...item,
                        id: item.goal_id,
                        label: investmentSelect == 1 ? item["short name"] : item["name"],
                        value: investmentSelect == 1 ? item["short name"] : item["name"],
                        meta_data: item.meta_data
                    };
                });

                setSelectedInvestOptions(goal_list);

            } else {

            }
        }));

    };

    // API - goal summary
    const _getGoalSummary = () => {

        let param = {
            "goal_id": selectedGoal.id,
            "type": null,
            "customer_id": selectedUser.id
        };

        _goalSummary(param).then((response => {
            if (response.status_code === 200) {
                setInvestSummary(response.data);
                setShowGoalInvestModal(true);
            } else {
                setInvestSummary([]);
                toast.dismiss();
                toast.error(response.data.message);
            };
        }));
    };

    // API - Basket summary 
    const _getBasketSummary = () => {

        let param = {
            "basket_id": selectedGoal.id,
            "customer_id": selectedUser.id
        };

        _basketSummary(param).then((response => {
            if (response.status_code === 200) {
                setInvestSummary(response.data);

                setShowBasketInvestModal(true);
            } else {
                setInvestSummary([]);
                toast.dismiss();
                toast.error(response.data.message);
            };
            setOrderInitiateLoader(false);
        }));
    };

    // API - MF summary 
    const _getMFSummary = () => {

        let param = {
            "mf_id": selectedFund.mf_id,
            "customer_id": selectedUser.id
        };

        _mfSummary(param).then((response => {
            if (response.status_code === 200) {
                setInvestSummary(response.data);
                setShowMFInvestModal(true);
            } else {
                setInvestSummary([]);
                toast.dismiss();
                toast.error(response.data.message);
            };
            setOrderInitiateLoader(false);
        }));
    };

    // API - calculate retirement corpus
    const _calculateRetirementCorpus = (corpus_data) => {

        let is_post = true;
        let url = "/goal/calculate-corpus";
        let data = {
            "current_age": corpus_data.current_age,
            "retirement_age": corpus_data.retire_age,
            "monthly_expenses": corpus_data.monthly_expense
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCalculatedCorpusData(response.data);
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            };
        });

    };

    // API - To get risk score data
    const _getRiskScore = () => {
        let is_post = false;
        let url = "/fund/investment_styles";

        service(is_post, url).then((response) => {
            if (response.status_code === 200) {

                let risk_score_data = response.data.map(item => {
                    return {
                        ...item,
                        value: item.id,
                        label: item.id
                    }
                });

                setRiskScoreData(risk_score_data);

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            };
        });
    };

    // API - Get Goal details
    // const _getGoalDetail = (goal_id) => {
    //     setCurrentStep(4);

    //     let is_post = true;
    //     let url = "/goal/detail";
    //     let data = {
    //         "goal_id": goal_id,
    //         "customer_id": selectedUser.id
    //     };

    //     service(is_post, url, data).then((response) => {
    //         if (response.status_code === 200) {
    //             setGoalData(response.data);

    //         } else {
    //             toast.dismiss();
    //             toast.error(response.data.message);
    //         };
    //     });
    // };

    // API - Get basket details
    const _getBasketDetail = (basket_id) => {

        let is_post = true;
        let url = "/goal/basket-detail";
        let data = {
            "basket_id": basket_id
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setBasketData(response.data);
                setSipBasket(response.data.min_investment.min_invest_sip);
                setLumpsumBasket(response.data.min_investment.min_invest);
                setCurrentStep(4);

                available_investment_types.current = {
                    sip: response.data.sip_enabled,
                    lumpsum: response.data.lumpsum_enabled,
                };

                let investment_tabs = [];

                if (response.data.sip_enabled) {
                    investment_tabs = [...investment_tabs, { id: 1, label: "SIP" }];
                }
                if (response.data.lumpsum_enabled) {
                    investment_tabs = [...investment_tabs, { id: 2, label: "Lumpsum" }]
                }

                if (response.data.sip_enabled) {
                    setActiveTabBasket(1);
                } else {
                    setActiveTabBasket(2);
                }

                setInvestmentBasketTabs([...investment_tabs]);

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            };
        });
    };

    // API - Get basket details
    const _getFundDetail = () => {

        let is_post = true;
        let url = "/fund/scheme/detail";
        let data = {
            "mf_id": selectedFund.mf_id,
            "customer_id": selectedUser.id
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                available_investment_types.current = {
                    sip: response.data.sip_available,
                    lumpsum: response.data.lumpsum_available,
                    available_for_purchase: response.data.available_for_purchase
                };
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            };
        });
    };

    // Switch fund flow APIs
    const _getUserHoldings = () => {

        let is_post = true;
        let url = "/goal/mf/holdings";
        let data = {
            "customer_id": selectedUser.id
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                let user_holdings = response.data.map(item => {
                    return {
                        ...item,
                        value: item.mf_id,
                        label: item.fund_name
                    }
                });
                setUserHoldings(user_holdings);
            } else {
                setUserHoldings([]);
            };
            setUserHoldingsLoader(false);
        });

    };

    // get holding switch funds
    const _getSwitchFunds = (mf_id) => {
        let is_post = true;
        let url = "/goal/mf/search-switch-in-schemes";
        let data = {
            "mf_id": mf_id,
            "query": null
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                let switch_holdings = response.data.funds.map(item => {
                    return {
                        ...item,
                        value: item.mf_id,
                        label: item.name
                    }
                });
                setSwitchHoldings(switch_holdings);

            } else {
                setSwitchHoldings([]);
            };
        });
    };

    return (
        <div className="row position-relative mt-3">
            <div className="col-12">
                <h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
                    Create investments
                </h3>
                <div className="px-4 pt-4 e-padding-bottom-80px bg-white min-vh-100 e-border-radius-16px  mt-4">
                    {/* investment type tab */}
                    <div className="e-tab-container">
                        <FundsTab index={tabId}
                            data={["Create order"]}
                            setTab={(id) => {
                                setTabId(id);
                            }} />
                    </div>

                    {/* Customer Select */}
                    {
                        currentStep >= 1 && showSearch &&
                        <div className="my-4" >
                            <p className="mb-2 e-filter-label">
                                Search user with Name, Customer ID or PAN*
                            </p>
                            <div className="col-4">
                                <CustomSearchSelect loading={loadingResults ? true : false}
                                    options={searchResults}
                                    handleClear={_handleClearUser}
                                    handleSelect={_handleSelectUser}
                                    handleSearch={_handleUserSearch} />
                            </div>
                        </div>
                    }
                    {/* Selected user details */}
                    {
                        (currentStep >= 2 || selectedUser["id"] !== undefined) &&
                        <>
                            <div className={`${showSearch === false && "mt-4"} e-bg-alice-blue py-3 pt-4 e-border-radius-8`}>
                                <h6 className="d-flex justify-content-between align-items-center px-4 e-text-chinese-black e-font-manrope e-font-weight-700 e-font-14">
                                    The selected user details:
                                    {
                                        showSearch === false &&
                                        <img src={Edit}
                                            width={14}
                                            height={14}
                                            className="cursor-pointer"
                                            alt="edit"
                                            onClick={_handleEdit} />
                                    }
                                </h6>

                                {/* Selected user details */}
                                <div className={"d-flex py-2"}>
                                    <div className="col-12 no-scrollbar overflow-auto">
                                        <table className="col-12">
                                            <thead>
                                                <tr>
                                                    {
                                                        ["User name", "Customer ID", "Email", " Phone no", "Risk score"].map((item, index) => (
                                                            <th className={`${index === 0 && "ps-4"} pe-3 text-nowrap pb-2 e-text-light-grey e-font-manrope e-font-weight-600  e-font-14 line-height-18px`}>
                                                                {item}
                                                            </th>

                                                        ))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="e-bg-smoky-black">
                                                    <td className={`ps-4 col-2 pe-3 mb-0  py-3 e-text-chinese-black e-font-manrope e-font-weight-400  e-font-14 line-height-18px`}>
                                                        {selectedUser.name} ({selectedUser.pan})
                                                    </td>
                                                    <td className={`col-1 pe-3 text-nowrap mb-0  py-3 e-text-chinese-black e-font-manrope e-font-weight-400  e-font-14 line-height-18px`}>
                                                        {selectedUser.customer_id}
                                                    </td>
                                                    <td className={`col-2 pe-3 mb-0  py-3 e-text-chinese-black e-font-manrope e-font-weight-400  e-font-14 line-height-18px`}>
                                                        <a href={`mailto:${selectedUser.email}`} className="text-reset">
                                                            {selectedUser.email}
                                                        </a>
                                                    </td>
                                                    <td className={`col-1 pe-3 text-nowrap mb-0  py-3 e-text-chinese-black e-font-manrope e-font-weight-400  e-font-14 line-height-18px`}>
                                                        <a href={`tel:${selectedUser.phone}`} className="text-reset">
                                                            {selectedUser.country_code}-{selectedUser.phone}
                                                        </a>
                                                    </td>
                                                    <td className={`col-1 pe-3 text-nowrap mb-0  py-3 e-text-chinese-black e-font-manrope e-font-weight-400  e-font-14 line-height-18px`}>
                                                        {
                                                            selectedUser.risk_score ? selectedUser.risk_score : (
                                                                <p className="mb-0 cursor-pointer d-flex gap-2 align-items-center"
                                                                    onClick={_handleShowRiskScore}>
                                                                    {
                                                                        riskScore > 0 &&
                                                                        <span className="pe-2">
                                                                            {riskScore}
                                                                        </span>
                                                                    }
                                                                    <img src={Edit}
                                                                        width={14}
                                                                        height={14}
                                                                        className="cursor-pointer"
                                                                        alt="edit"
                                                                    />
                                                                    Edit
                                                                </p>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {
                                selectedUser["id"] && showSearch &&
                                <div className="col-2 mt-4">
                                    <PrimaryBtn name="Continue"
                                        className="d-flex align-items-center justify-content-center w-100 h-40"
                                        handleClick={_handleShowGoalSelect} />
                                </div>
                            }
                        </>
                    }

                    {/* Selecting Goal */}
                    {
                        currentStep >= 3 &&
                        <div className="mt-4">
                            {/* Investment type */}
                            <div className="mb-3">
                                <p className="mb-2 e-filter-label">
                                    Investment type*
                                </p>
                                <div className='d-flex flex-wrap gap-3'>
                                    <RadioGroup data={INVESTMENT_TYPE}
                                        selected={investmentSelect}
                                        handleSelect={_handleInvestmentSelect} />
                                </div>
                            </div>

                            {
                                // Switch Fund
                                investmentSelect === 4 ?
                                    <div>
                                        {
                                            userHoldingsLoader === false && userHoldings.length == 0 ?
                                                <div>
                                                    <EmptyNote title="No holdings found" note="User doesn't have any holdings." />
                                                </div>
                                                :
                                                <div className="mb-5 pb-5">
                                                    {/* Select fund from */}
                                                    <div>
                                                        {/* Fund select dropdown */}
                                                        <div className="col-4">
                                                            <p className="mb-2 e-filter-label">
                                                                Fund from
                                                            </p>
                                                            <SelectBox
                                                                value={selectedSwitchHoldingsFrom}
                                                                placeholder="Select fund"
                                                                noOptionMessage={"No found found"}
                                                                // isSearchable={fal}
                                                                options={userHoldings}
                                                                selectChange={_handleSelectHoldingFromToSwitch}
                                                            />
                                                        </div>
                                                        {/* selected fund details */}
                                                        {
                                                            selectedSwitchHoldingsFrom &&
                                                            <div className="e-bg-alice-blue p-3 mt-3 e-border-radius-8">
                                                                <div className="d-flex gap-2 align-items-center">
                                                                    <img src={selectedSwitchHoldingsFrom.image}
                                                                        alt="fund-logo"
                                                                        className="object-fit-contain e-border-radius-10 me-1"
                                                                        width={32}
                                                                        height={32} />
                                                                    <p className='mb-0 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-22px'>
                                                                        {selectedSwitchHoldingsFrom.fund_name} ({selectedSwitchHoldingsFrom.asset_class})
                                                                    </p>
                                                                </div>
                                                                <div className="mt-3">
                                                                    <div className='row'>
                                                                        <div className="col-3">
                                                                            <p className="e-filter-label mb-1">
                                                                                Available switch amount
                                                                            </p>
                                                                            <p className="e-filter-label mb-0">
                                                                                <span className="me-2">
                                                                                    ₹{selectedSwitchHoldingsFrom.switch_data.available_to_switch_amount.toLocaleString("en-IN")}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <p className="e-filter-label mb-1">
                                                                                Available switch units
                                                                            </p>
                                                                            <p className="e-filter-label mb-0">
                                                                                {selectedSwitchHoldingsFrom.switch_data.available_to_switch_units}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <p className="e-filter-label mb-1">
                                                                                Min switch amount
                                                                            </p>
                                                                            <p className="e-filter-label mb-0">
                                                                                ₹{selectedSwitchHoldingsFrom.switch_data.min_switch_amount.toLocaleString("en-IN")}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <p className="e-filter-label mb-1">
                                                                                Min switch units
                                                                            </p>
                                                                            <p className="e-filter-label mb-0">
                                                                                {selectedSwitchHoldingsFrom.switch_data.min_switch_units}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    {/* Select Folio  */}
                                                    {
                                                        selectedSwitchHoldingsFrom &&
                                                        <div className="mt-3">
                                                            <div className="col-4">
                                                                <p className="mb-2 e-filter-label">
                                                                    Folio
                                                                </p>
                                                                <SelectBox
                                                                    noOptionMessage={"No folio found"}
                                                                    value={selectedFolio}
                                                                    placeholder="Select folio"
                                                                    // isSearchable={fal}
                                                                    options={folioData}
                                                                    selectChange={_handleSelectFolio}
                                                                />
                                                            </div>

                                                            {/* selected fund details */}
                                                            {
                                                                selectedFolio &&
                                                                <div className="e-bg-alice-blue p-3 mt-3 e-border-radius-8">

                                                                    <div className="row">
                                                                        <div className='col-3'>
                                                                            <div className="d-flex flex-column">
                                                                                <p className="e-filter-label e-font-weight-600  mb-1">
                                                                                    Folio
                                                                                </p>
                                                                                <p className="e-filter-label mb-0">
                                                                                    {selectedFolio.folio_number}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-3'>
                                                                            <div className="d-flex flex-column">
                                                                                <p className="e-filter-label e-font-weight-600  mb-1">
                                                                                    Amount
                                                                                </p>
                                                                                <p className="e-filter-label mb-0">
                                                                                    ₹{selectedFolio.amount.toLocaleString("en-IN")}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-3'>
                                                                            <div className="d-flex flex-column">
                                                                                <p className="e-filter-label e-font-weight-600  mb-1">
                                                                                    Units
                                                                                </p>
                                                                                <p className="e-filter-label mb-0">
                                                                                    {selectedFolio.units}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {/* Select fund to  */}
                                                    <div>
                                                        {/* Fund select dropdown */}
                                                        {
                                                            selectedSwitchHoldingsFrom && selectedFolio &&
                                                            <div className="col-4 mt-3">
                                                                <p className="mb-2 e-filter-label">
                                                                    Fund To
                                                                </p>
                                                                <SelectBox
                                                                    value={selectedSwitchHoldingsTo}
                                                                    placeholder="Select fund"
                                                                    noOptionMessage={"No found found"}
                                                                    // isSearchable={fal}
                                                                    options={switchHoldings}
                                                                    selectChange={_handleSelectHoldingToSwitch}
                                                                />
                                                            </div>
                                                        }
                                                        {/* selected fund details */}
                                                        {
                                                            selectedSwitchHoldingsTo &&
                                                            <div className="e-bg-alice-blue p-3 mt-3 e-border-radius-8">
                                                                <div className="d-flex gap-2 align-items-center">
                                                                    <img src={selectedSwitchHoldingsTo.icon}
                                                                        alt="fund-logo"
                                                                        className="object-fit-contain e-border-radius-10 me-1"
                                                                        width={32}
                                                                        height={32} />
                                                                    <p className='mb-0 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-22px'>
                                                                        {selectedSwitchHoldingsTo.name} ({selectedSwitchHoldingsTo.asset_class})
                                                                    </p>
                                                                </div>
                                                                {/* <p className="e-filter-label mb-0 mt-2">
                                                                    <span className="e-font-weight-600 pe-1">
                                                                        Min switch amount :
                                                                    </span>
                                                                    ₹{selectedSwitchHoldingsTo.min_switch_amount.toLocaleString("en-IN")}
                                                                </p> */}
                                                                <div className="col-3 mt-3">
                                                                    <p className="e-filter-label mb-1">
                                                                        Min switch amount
                                                                    </p>
                                                                    <p className="e-filter-label mb-0">
                                                                        ₹{selectedSwitchHoldingsTo.min_switch_amount.toLocaleString("en-IN")}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    {/* Amount/Units Input */}
                                                    {
                                                        selectedSwitchHoldingsTo &&
                                                        <div className="mt-4">
                                                            <div className='d-flex flex-wrap gap-3'>
                                                                <RadioGroup data={SWITCH_TYPE}
                                                                    selected={selectedSwitchType}
                                                                    handleSelect={_handleSwitchTypeSelect} />
                                                            </div>

                                                            {
                                                                selectedSwitchType === "AMOUNT" ?
                                                                    <div className="col-4 mt-3 pb-1"
                                                                        id='switch-amount'>
                                                                        <CustomAmountInput label='Amount'
                                                                            require
                                                                            wrapperClassName={switchAll ? "pe-none" : ""}
                                                                            placeholder={0}
                                                                            value={switchAmount}
                                                                            errorMsg={switchAmountError}
                                                                            maxLength={8}
                                                                            handleChange={_handleSwitchAmountChange} />
                                                                    </div>
                                                                    :
                                                                    <div className="col-4 mt-3"
                                                                        id='switch-amount'>
                                                                        <CustomTextInput label="Unit"
                                                                            type={"number"}
                                                                            require
                                                                            className={`${switchAll ? "pe-none" : ""} cursor-pointer`}
                                                                            placeholder="0"
                                                                            value={switchUnit}
                                                                            errorMsg={switchUnitError}
                                                                            handleChange={_handleSwitchUnitChange} />
                                                                    </div>
                                                            }

                                                            {/* Switch all checkbox */}
                                                            <CheckBox label="Switch all"
                                                                check={switchAll}
                                                                handleCheck={_handleSwitchAll} />

                                                            <PrimaryBtn className="mt-4 col-3 d-flex align-items-center justify-content-center h-40"
                                                                name="Confirm Switch"
                                                                loading={switchLoader}
                                                                handleClick={_handleConfirmSwitch} />
                                                        </div>
                                                    }
                                                </div>
                                        }
                                    </div>
                                    :
                                    // Fund Investment
                                    investmentSelect === 3 ?
                                        <div className="position-relative">
                                            <p className="mb-2 e-filter-label">
                                                Choose fund*
                                            </p>
                                            <div className="col-3 position-relative">
                                                <div className="d-flex align-items-center e-bg-search-bg e-border-radius-4">
                                                    <SearchInput placeholder="Search funds"
                                                        value={fundSearch}
                                                        valueChange={(value) => setFundSearch(value)}
                                                        onSearch={() => _searchFunds()} />
                                                    {
                                                        fundSearch.length > 0 &&
                                                        <button className={`${styles.e_search_close} pe-3`}
                                                            onClick={_handleClearFundSearch} >
                                                        </button>
                                                    }
                                                </div>
                                            </div>

                                            {
                                                fundList.length > 0 && selectedFund == "" &&
                                                <p className="mt-3 mb-1 e-filter-label">
                                                    Search Results
                                                </p>
                                            }

                                            {
                                                selectedFund ?
                                                    <div>
                                                        <p className="mt-3 mb-1 e-filter-label">
                                                            Selected fund
                                                        </p>
                                                        <div className="d-flex justify-content-between align-items-center  mt-3 e-bg-search-bg e-border-radius-8 py-3  e-font-14 e-text-manrope e-font-weight-500 mb-0 py-2 px-3">

                                                            {selectedFund.scheme_name}
                                                            <span onClick={_handleChangeSelectedFund} className="d-flex align-items-center cursor-pointer">
                                                                <img src={Delete}
                                                                    width={14}
                                                                    height={14}
                                                                    className="cursor-pointer me-2"
                                                                    alt="edit" />
                                                                Delete
                                                            </span>

                                                        </div>
                                                        <div className="mt-4 pt-2 mb-2">
                                                            <PrimaryBtn className="col-3 d-flex align-items-center justify-content-center h-40"
                                                                name="Initiate transaction"
                                                                loading={orderInitiateLoader}
                                                                handleClick={_handleInitiateMFInvest} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="h-240px position-relative d-flex align-items-center overflow-auto e-manage-scrollbar mt-3 e-bg-search-bg e-border-radius-8 py-3">
                                                        {
                                                            fundLoader == false ?
                                                                fundList.length > 0 ?
                                                                    <div className="d-flex flex-column w-100">
                                                                        {
                                                                            fundList.map(item => (
                                                                                <p id="order-fund"
                                                                                    className="cursor-pointer e-font-14 e-text-manrope e-font-weight-500 mb-0 p-2"
                                                                                    onClick={() => setSelectedFund(item)}>
                                                                                    {item.scheme_name}
                                                                                </p>
                                                                            ))
                                                                        }

                                                                    </div>
                                                                    :
                                                                    <div className='mx-auto'>
                                                                        <img src={SearchFunds}
                                                                            draggable={false}
                                                                            alt="empty"
                                                                            className="img-fluid" />
                                                                        <p className='pt-2 mb-0 text-center e-font-manrope e-font-16 e-font-weight-500'>
                                                                            {
                                                                                initialRender.current === false ? "No funds found" : "Fund results will appear here"
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                :
                                                                <div className="mt-5">
                                                                    <Loader />
                                                                </div>
                                                        }
                                                    </div>
                                            }

                                        </div>
                                        :
                                        <>
                                            <p className="mb-2 e-filter-label">
                                                {investmentSelect === 1 ? "Select goal*" : "Select basket*"}
                                            </p>

                                            <div className="col-4 position-relative">
                                                <SelectBox
                                                    value={selectedGoal.length !== 0 ? selectedGoal : null}
                                                    options={selectedInvestOptions}
                                                    selectChange={_handleGoalSelect}
                                                    placeholder={investmentSelect === 1 ? "Select goal" : "Select basket"}
                                                    isSearchable={false} />
                                            </div>
                                        </>
                            }

                        </div>
                    }

                    {/*  Goal related inputs */}
                    {
                        currentStep >= 4 &&
                        <>
                            {
                                selectedGoal.is_goal === true ?
                                    (
                                        <div className="mt-4">
                                            {/* Goal Details */}
                                            {
                                                selectedGoal.length !== 0 &&
                                                <div className="mt-4">
                                                    {/* Selected goal banner  */}
                                                    <div className="d-flex align-items-center gap-3 e-bg-goal e-border-radius-12 p-4 mb-3">
                                                        <img src={selectedGoal.icon}
                                                            alt="goal img"
                                                            className="width-56px" />
                                                        <p className="e-text-chinese-black e-font-manrope e-font-weight-700  e-font-14 line-height-18 ms-1 mb-0 ">
                                                            The life goal {selectedGoal.value.toLowerCase()} has been selected. Now you can enter the details</p>
                                                    </div>
                                                    {
                                                        selectedGoal.value === "Pension" ? (
                                                            <RetirementGoalCard title={title}
                                                                tenureMin={tenureMin}
                                                                tenureMax={tenureMax}
                                                                corpusData={calculatedCorpusData}
                                                                loading={goalLoader}
                                                                selectedGoal={goalListData.find(item => item["short name"] === selectedGoal.value)}
                                                                onEditCorpus={() => setCurrentStep(4)}
                                                                handleCalculateCorpus={_handleCalculateCorpus}
                                                                handleUpdateInvestLumpsumChange={_handleInvestLumpsumChange}
                                                                handleSetGoal={_handleSetGoal} />
                                                        ) : (
                                                            <LifeGoalCard title={title}
                                                                tenureMin={tenureMin}
                                                                tenureMax={tenureMax}
                                                                loading={goalLoader}
                                                                selectedGoal={goalListData.find(item => item["short name"] === selectedGoal.value)}
                                                                handleUpdateInvestLumpsumChange={_handleInvestLumpsumChange}
                                                                handleSetGoal={_handleSetGoal} />
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    ) : (
                                        <div className="mt-4">
                                            <div className="d-flex align-items-center gap-3 e-bg-lavender e-border-radius-12 p-4 mb-3">
                                                <img src={selectedGoal.icon}
                                                    alt="goal img"
                                                    className="width-56px" />
                                                <p className="e-text-chinese-black e-font-manrope e-font-weight-700  e-font-14 line-height-18 ms-1 mb-0 ">
                                                    {selectedGoal.name} has been selected. Now you can enter details
                                                </p>
                                            </div>

                                            <div className="mt-4 e-bg-cultured p-4 e-border-radius-12">
                                                <div className="d-flex gap-5">
                                                    {
                                                        basketData &&
                                                        <>
                                                            {
                                                                selectedGoal.meta_data.lumpsum_enabled === true &&
                                                                <p className="d-flex flex-column gap-2 e-font-manrope e-text-chinese-black e-font-weight-600">
                                                                    <span className="e-font-14">Min investment</span>
                                                                    <span className="e-font-14">₹{basketData?.min_investment.min_invest.toLocaleString("en-IN")}</span>
                                                                </p>
                                                            }
                                                            {
                                                                selectedGoal.meta_data.sip_enabled === true &&
                                                                <p className="d-flex flex-column gap-2 e-font-manrope e-text-chinese-black e-font-weight-600">
                                                                    <span className="e-font-14">Min SIP</span>
                                                                    <span className="e-font-14">₹{basketData?.min_investment.min_invest_sip.toLocaleString("en-IN")}</span>
                                                                </p>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <div className="e-tab-container mt-2">
                                                    <FundsTab index={activeTabBasket}
                                                        invest={true}
                                                        data={investmentBasketTabs}
                                                        setTab={_handleBasketInvestSelect} />
                                                    <div className="col-sm-5 col-md-4 col-lg-3 mt-3">
                                                        <CustomAmountInput require
                                                            label={activeTabBasket === 1 ? "Monthly amount " : "One time investment amount "}
                                                            value={activeTabBasket === 1 ? sipBasket : lumpsumBasket}
                                                            maxLength={8}
                                                            errorMsg={activeTabBasket === 1 ? sipBasketErr : lumpsumBasketErr}
                                                            placeholder={activeTabBasket === 1 ? "SIP amount" : "Lumpusm amount"}
                                                            handleChange={_handleOnAmountInputChange} />
                                                    </div>
                                                    <PrimaryBtn className="mt-3 col-auto d-flex align-items-center justify-content-center h-40"
                                                        name="Initiate transaction"
                                                        loading={orderInitiateLoader}
                                                        handleClick={_handleInitiateBasketInvest} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        </>
                    }

                    {/* Goal Summary */}
                    {
                        currentStep >= 5 &&
                        <div>
                            <div className="e-bg-ghost-white e-border-radius-8 p-4 mt-4">
                                <div className="row">
                                    <div className="d-flex align-items-center gap-2 mb-4">
                                        <IcoMoon iconSet={iconSet}
                                            icon={"info"}
                                            size={"1.25rem"} />
                                        <h6 className="mb-0 e-text-chinese-black e-font-manrope e-font-weight-700 e-font-14 line-height-18px">
                                            The recommended SIP amount is
                                            <span className="ps-1 e-text-primary e-font-inter e-font-weight-700">
                                                ₹{goalSummary.sip.toLocaleString("en-In")}
                                            </span>
                                        </h6>
                                    </div>

                                    <div id="monthly-sip" className="col-4">
                                        <CustomAmountInput
                                            label="Monthly SIP"
                                            value={monthlySip}
                                            placeholder="0"
                                            errorMsg={monthlySipErr}
                                            handleChange={_handleMonthlySipChange} />
                                    </div>
                                    {
                                        lumpsumAmt > 0 &&
                                        <div id="lumpsum-amt" className="col-4">
                                            <CustomAmountInput
                                                label="Lumpsum amount"
                                                value={lumpsumAmt}
                                                placeholder="0"
                                                errorMsg={lumpsumAmtErr}
                                                handleChange={_handleLumpsumAmtChange} />
                                        </div>
                                    }
                                    <div id="tenure" className="col-4">
                                        <div className="d-flex justify-content-between pt-1 mb-3">
                                            <label className={`e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14`}>
                                                Tenure
                                            </label>
                                            <span className="e-text-primary e-font-14 e-font-manrope e-font-weight-800">
                                                {tenure} {tenure > 1 ? "Yrs" : "Yr"}
                                            </span>
                                        </div>

                                        <CustomSlider min={tenureMin}
                                            max={tenureMax}
                                            step={1}
                                            labelType="Year"
                                            defaultValue={tenure}
                                            handleChange={_handleTenureChange} />
                                    </div>
                                </div>
                            </div>

                            {/*  investment details*/}
                            <div className="e-bg-ghost-white e-border-radius-8 p-4 mt-4">
                                <h6 className="mb-3 e-text-chinese-black e-font-manrope e-font-weight-700 e-font-14 line-height-18px">
                                    The investment details:
                                </h6>
                                <div className="row justify-content-between row-gap-3">
                                    <span className="col-auto e-text-chinese-black e-font-manrope e-font-weight-400  e-font-20 line-height-18px">
                                        Monthly SIP:
                                        <span className="ps-1 e-text-primary e-font-inter e-font-weight-600">
                                            ₹{monthlySip.toLocaleString("en-IN")}
                                        </span>
                                    </span>
                                    {
                                        lumpsumAmt > 0 &&
                                        <span className="col-auto e-text-chinese-black e-font-manrope e-font-weight-400  e-font-20 line-height-18px">
                                            Lumpsum amount:
                                            <span className="ps-1 e-text-primary e-font-inter e-font-weight-600">
                                                ₹{lumpsumAmt.toLocaleString("en-IN")}
                                            </span>
                                        </span>
                                    }
                                    <span className="col-auto e-text-chinese-black e-font-manrope e-font-weight-400  e-font-20 line-height-18px">
                                        Tenure:
                                        <span className="ps-1 e-text-primary e-font-inter e-font-weight-600">
                                            {tenure}{tenure > 1 ? "Yrs" : tenure !== "" ? "Yr" : "-"}
                                        </span>
                                    </span>
                                </div>
                            </div>

                            {/* Recommended funds */}
                            <div className="e-bg-ghost-white e-border-radius-8 py-4 mt-4">
                                <h6 className="px-4 mb-4 e-text-chinese-black e-font-manrope e-font-weight-700 e-font-14 line-height-18px">
                                    The recommended fund allocations
                                </h6>
                                <div className="col-7 col-md-6 col-lg-5 col-xl-4 px-4">
                                    <div className="d-flex justify-content-between">
                                        <span className="e-text-sonic-silver  e-font-manrope e-font-12 e-font-weight-400 line-height-12px">
                                            0%
                                        </span>
                                        <span className="e-text-sonic-silver  e-font-manrope e-font-12 e-font-weight-400 line-height-12px">
                                            100%
                                        </span>
                                    </div>
                                    {/* Fund Allocation bar */}
                                    <div className="progress-stacked e-border-radius-12 h-40 mt-2 mb-4">
                                        {goalSummary.asset_allocation.map((item, key) => (
                                            <div style={{ width: `${item.percentage}%` }}
                                                className="progress h-100"
                                                role="progressbar"
                                                key={key} >
                                                <div style={{ backgroundColor: item.color }}
                                                    className="progress-bar"></div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="d-flex flex-column gap-3 gap-sm-4">
                                        <div className="d-flex justify-content-between">
                                            <span className="col-6 col-md-7 e-text-light-grey  e-font-manrope e-font-14 e-font-weight-600 line-height-18px letter-spacing-neg-2">
                                                Name
                                            </span>
                                            <span className="col-6 col-md-2 text-center e-text-light-grey e-font-manrope e-font-14 e-font-weight-600 line-height-18px letter-spacing-neg-2">
                                                Allocation
                                            </span>
                                        </div>

                                        {
                                            goalSummary.asset_allocation.map((item, key) => (
                                                <div className="d-flex align-items-center justify-content-between" key={key}>
                                                    <span className="e-font-14-res col-6 col-md-7 d-flex align-items-center e-text-chinese-black  e-font-manrope e-font-14 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                        <IcoMoon icon="bullet"
                                                            size={"0.625rem"}
                                                            color={item.color}
                                                            iconSet={iconSet}
                                                            className="me-2" />
                                                        {item.asset_class}
                                                    </span>
                                                    <span className="e-font-14-res col-6 col-md-2 text-center align-self-center e-text-chinese-black  e-font-manrope e-font-14 e-font-weight-400 line-height-24px letter-spacing-neg-2">
                                                        {item.percentage}%
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                {/* Fund Data table */}
                                <div id="fund-allocation-table" className="mt-2 pt-2 cursor-default">

                                    <DataTable
                                        pagination={false}
                                        columns={FUND_COLUMNS}
                                        data={goalSummary.scheme_allocation}
                                        selectableRows={false}
                                        pointerOnHover={false}
                                        customStyles={{
                                            rows: {
                                                style: {
                                                    cursor: "default"
                                                },
                                            }
                                        }}
                                    // selectRows={_handleSelectUser}
                                    // rowClick={(row) => alert("coming soon")}
                                    />
                                </div>
                                <div className="px-4 mt-4 pt-2 mb-2">
                                    <PrimaryBtn className="col-auto d-flex align-items-center justify-content-center h-40"
                                        name="Initiate transaction"
                                        loading={orderInitiateLoader}
                                        handleClick={_handleInitiateGoalInvest} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {/* Modal */}
            {
                showRiskScoreSelect &&
                <SetRiskScoreModal customerId={selectedUser.id}
                    riskScoreData={riskScoreData}
                    riskScore={riskScore}
                    handleSetRiskScore={_handleSetRiskScore}
                    onCloseModal={() => setShowRiskScoreSelect(false)} />
            }
            {
                showGoalInvestModal &&
                <GoalInvestModal userData={selectedUser}
                    goalId={selectedGoal.id}
                    summary={investSummary}
                    onSuccess={_handleInvestStatus}
                    onCloseModal={() => setShowGoalInvestModal(false)} />
            }
            {
                showBasketInvestModal && investSummary &&
                <BasketInvestModal userData={selectedUser}
                    sip={sipBasket}
                    lumpsum={lumpsumBasket}
                    availableInvestmentTypes={available_investment_types}
                    investType={activeTabBasket}
                    basketId={selectedGoal.id}
                    basketSummary={investSummary}
                    onCloseModal={_handleCloseInvestModal}
                    onSuccess={_handleInvestStatus} />
            }

            {
                showMFInvestModal &&
                <MFInvestModal userData={selectedUser}
                    availableInvestmentTypes={available_investment_types.current}
                    fundSummary={investSummary}
                    onCloseModal={_handleCloseMFInvestModal}
                    onSuccess={_handleInvestStatus} />
            }
            {
                showOrderStatusModal &&
                <OrderStatusModal paymentURL={paymentURL}
                    mandateURL={mandateURL}
                    userData={selectedUser}
                    onClose={() => setShowOrderStatusModal(false)} />
            }

            {
                showSwitchOTPModal &&
                <VerifySwitchOTPModal
                    data={{
                        "user_data": selectedUser,
                        "switch_in_mf_id": selectedSwitchHoldingsTo.mf_id,
                        "switch_out_mf_id": selectedSwitchHoldingsFrom.mf_id,
                        "switch_all": switchAll,
                        "folio_number": selectedFolio.folio_number,
                        "units": switchUnit,
                        "amount": switchAmount,
                    }}
                    handleOrderSuccess={_handleSuccessSwitchOrder}
                    onCloseModal={_handleCloseSwitchModal} />
            }

        </div >
    );
};

export default ConfigureGoal;


