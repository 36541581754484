/*
 *   File : addFundClass.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : add fund class name and adding fund modal
 *   Integrations :28-11-2023
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import components*/
import { Input, SearchInput } from '../Input/index';
import { SecondaryBtn, PrimaryBtn, PrimaryLink } from '../Cta/index';
import { AggressiveFund } from '../Cards/index';
import { Toast } from '../Note';
import { Loader } from '../Loader';

/*import styles*/
import fundStyles from '../../Styles/Components/fund-class.module.scss';
import styles from '../../Styles/Components/search-input.module.scss';

/*import assets*/
import SearchFunds from '../../Assets/Images/DataFormating/search-funds.svg'
// import Show from '../../Assets/Images/Card/show.svg';
// import Hide from '../../Assets/Images/Card/hide.svg';
// import Add from '../../Assets/Images/modal/add.svg';
/*import services */
import service from '../../Services/service';

export default function FundClass(props) {

	const [name, setName] = useState("");
	const [search, setSearch] = useState("");
	const [funds, setFunds] = useState([]);
	//eslint-disable-next-line
	const [fundId, setFundId] = useState(null);
	const [selectedFunds, setSelectedFunds] = useState([]);
	const [error, setError] = useState(null);
	const [allocation, setAllocation] = useState(props.totalAllocation);
	const [response, setResponse] = useState(null);
	const [searchLoader, setSearchLoader] = useState(false);
	//eslint-disable-next-line
	const [yearId, setYearId] = useState(1);
	const [loader, setLoader] = useState(false);
	const [success, setSuccess] = useState(null);
	const [show, setShow] = useState(false);
	const [addedFunds, setAddedFunds] = useState(false);
	const [edit, setEdit] = useState(false);


	useEffect(() => {
		setAllocation(props.totalAllocation);
	}, [props.totalAllocation])

	useEffect(() => {
		var myModalEl = document.getElementById('addFundClass')
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			setYearId(1)
			props.close()
			setName("");
			setAllocation("");
			setSearch("");
			setFunds([]);

			setResponse(null);
			setAddedFunds(false);
			setSelectedFunds([]);
			setShow(false);
		})
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error])

	useEffect(() => {
		if (props.editedData && Object.keys(props.editedData).length > 0) {
			setName(props.editedData[0].asset_class);
			// setAllocation(props.editedData.reduce((a, b) => a + b.allocation, 0));
			setEdit(true);
		} else {
			setEdit(false);
		}
	}, [props.editedData])

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success])

	useEffect(() => {
		if (!props.goal && props.year && props.year.value) {
			setYearId(props.year.value)
		}
	}, [props.goal, props.year])

	/* ---- search funds api ---*/
	const _searchFunds = (query, first = true) => {
		setSearchLoader(first)
		const url = '/fund/search'

		const data = {
			query: query ? query : search,
			page_num: 1,
		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setFunds(response.data);
				setResponse(response.status);
				setSearchLoader(false);
			}
			else {
				// setError(response.data.message);
				setSearchLoader(false);
				setFunds([]);
			}
		});
	};

	/* --- get scheme code of fund --*/
	const _getData = (item) => {
		setFundId(item?.scheme_code)
	};

	/*-----save fund api---- */
	const _saveFund = (value) => {

		const requested_funds = []

		selectedFunds.map((item) => {
			return (
				requested_funds.push({
					"asset_class": name,
					"scheme_code": item.scheme_code,
					"allocation": parseFloat(item.allocation),
					"investment_style_id": props.investmentStyle,
					"fund_alias": item.fund_alias,
				})
			)
		})
		setLoader(true);

		const url = '/fund/add'

		const data = {
			funds: requested_funds

		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setSuccess("Fund added successfully");
				setTimeout(() => {
					setFunds(response.data);
					$('#addFundClass').trigger('click');
					$('.btn-close').trigger('click');
					props.addedFund(true)
					setAllocation("");
					setName("");
					setFunds([]);
					setSearch("");
				}, 1000)
				setLoader(false);

			}
			else {
				setError(response.data.message);
				setLoader(false);
			}
		})
	};

	const _saveFundToBasket = (value) => {

		const requested_funds = []

		selectedFunds.map((item) => {
			return (
				requested_funds.push({
					"scheme_code": item.scheme_code,
					"allocation": parseFloat(item.allocation),
					"fund_alias": item.fund_alias,
				})
			)
		});


		setLoader(true);

		const url = '/baskets/update'

		const data = {
			basket_id: props.investmentStyle,
			funds: requested_funds

		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setSuccess("Fund added successfully");
				setTimeout(() => {
					setFunds(response.data);
					$('#addFundClass').trigger('click');
					$('.btn-close').trigger('click');
					props.addedFund(true)
					setAllocation("");
					setName("");
					setFunds([]);
					setSearch("");
				}, 1000)
				setLoader(false);

			}
			else {
				setError(response.data.message);
				setLoader(false);
			}
		})
	};

	return (
		<>
			<div className={`${fundStyles.e_add_fund} modal fade `}
				id="addFundClass"
				tabIndex="-1"
				data-bs-backdrop="static"
				aria-labelledby="success"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
					<div className="modal-content p-0" >
						<div className="modal-header p-4 pb-3">
							<h6> Add fund </h6>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									setName("");
									setAllocation("");
									setSearch("");
									setFunds([]);

									setResponse(null);
								}}>
							</button>
						</div>

						<div className="modal-body p-4 py-0 no-scrollbar ">


							<div className="row flex-nowrap">
								<div className="col-6 col-sm-8 flex-fill">
									<label>Asset class*</label>
									<Input
										placeholder="Eg: Indian equities"
										value={name}
										valueChange={(value) => setName(value)}
										type="text"
										disabled={edit}
									/>
								</div>
								<div className="col-6 col-sm-4">
									<label>Total asset class allocation</label>
									<Input
										placeholder="100"
										value={
											allocation
										}
										disabled={true}
										// valueChange={(value) => setAllocation(value)}
										type="number"
									/>
								</div>
							</div>
							<div className='row '>
								{/* <div className='col-5 d-flex mt-3'>
									{
										name ?
											<>
												<label>Fund name : </label>
												<h6 className='ps-2 mb-0'>{name} {allocation ? ` ${allocation}%` : null}</h6>
											</>
											:
											null
									}
								</div> */}
								<div className='col-auto ms-auto  mt-2'>
									{
										// selectedFunds.length > 0 && addedFunds == false ?
										addedFunds == false ?
											show == false ?
												<div className={`${selectedFunds.length == 0 && "opacity-0 pe-none"} d-flex justify-content-end align-items-center cursor-pointer`}
													onClick={() => {
														setShow(true)
													}}>
													<p className={` mb-0 ${fundStyles.e_selected_text}`}>Show selected funds</p>
												</div>
												:
												<div className={` d-flex justify-content-end align-items-center cursor-pointer`}
													onClick={() => {
														setShow(false)
													}}>
													<p className={` mb-0 ${fundStyles.e_selected_text}`}>
														Select more funds
													</p>
												</div>
											:
											null

									}

								</div>
							</div>
							{
								!addedFunds ?
									<div className="row mt-0">
										<div className="col-12">
											{
												!show ?
													<div className="row">
														<div className="col-lg-12 position-relative mb-4 ">
															<label>Search funds</label>
															<div className="d-flex align-items-center e-bg-search-bg e-border-radius-4">
																<SearchInput placeholder="Search for funds"
																	value={search}
																	valueChange={(value) => { setSearch(value) }}
																	onSearch={(value) => { _searchFunds(value) }} />
																{
																	search && search.length ?
																		<>
																			<button className={`${fundStyles.e_search_close} pe-3`} onClick={() => { setSearch(""); }} ></button>
																			<span className={`${fundStyles.e_search_submit} `} onClick={() => { _searchFunds(); }}></span>
																		</>
																		:
																		null
																}
															</div>
														</div>
													</div>
													:
													null
											}
											<div className="row e-funds-list position-relative">
												<div className="col-12">



													{
														response !== null ?
															funds?.length > 0 ?
																<>
																	<p className='mb-0'>Results</p>
																	{
																		show ?
																			selectedFunds.map((item, key) => {
																				return (
																					<>
																						<div key={key} onClick={() => { _getData(item); }}>
																							<AggressiveFund
																								selectedFunds={selectedFunds}
																								data={item}
																								getSelectedFunds={(temp) => {
																									setSelectedFunds(temp);
																									if (temp.length == 0) {
																										setShow(false);
																									}

																								}} />
																						</div>
																					</>
																				)
																			})
																			:

																			funds.map((item, key) => {
																				return (
																					<>

																						<div key={key} onClick={() => { _getData(item); }}>
																							<AggressiveFund
																								selectedFunds={selectedFunds}
																								data={item}
																								getSelectedFunds={(temp) => {
																									setSelectedFunds(temp);


																								}} />

																						</div>
																					</>
																				)
																			})
																	}
																</>
																:
																<div className="row">
																	<h6 className="text-center"> No mutual funds found </h6>
																</div>

															:
															!searchLoader ?
																<div align="center" className='mt-0'>
																	<img src={SearchFunds} draggable={false} alt="empty" />
																	<p className='pt-1 mb-0'>Search Funds</p>
																</div>
																:
																<Loader />
													}
												</div>
											</div>
										</div>
									</div>
									:
									<>
										<div className='row mt-2'>
											<div className='col-6'>
												<label className='e-font-12'>
													Selected funds
												</label>
											</div>
											<div className='col-3'>
												<label className='e-font-12'>
													Fund alias
												</label>
											</div>
											<div className='col-3'>
												<label className='e-font-12'>
													Allocation
												</label>
											</div>
										</div>
										{
											selectedFunds.map((item, key) => {
												return (
													<div className='row align-items-center my-2'>
														<div className='col-6'>
															<h6>{item.scheme_name}</h6>
														</div>
														<div className='col-3'>
															<Input type="text"
																value={item.fund_alias}
																valueChange={(value) => {
																	const tempSchemes = [...selectedFunds]

																	if (value === "") {
																		tempSchemes[key].fund_alias = null;
																	} else {
																		tempSchemes[key].fund_alias = value;
																	}

																	setSelectedFunds(tempSchemes)
																}}
															/>
														</div>
														<div className='col-3 position-relative'>
															<Input type="number"
																value={item.allocation}
																suffix="%"
																valueChange={(value) => {
																	if (value.length <= 3) {
																		const tempSchemes = [...selectedFunds];

																		// if (value === "") {
																		// 	tempSchemes[key].allocation = "";
																		// } else {
																		// 	tempSchemes[key].allocation = parseInt(value);
																		// }

																		tempSchemes[key].allocation = parseInt(value);

																		// const length = tempSchemes.reduce((a, b) => a + b.allocation, 0);
																		// setAllocation(length);

																		// if (tempSchemes.some((item) => (item.allocation) > 0)) {
																		// 	const length = tempSchemes.reduce((a, b) => a + b.allocation, 0);
																		// 	// setAllocation((prev) => prev +  length);
																		// } else {
																		// 	setAllocation("")
																		// }

																		setSelectedFunds(tempSchemes);
																	}
																}}
															/>
														</div>
													</div>
												)
											})
										}
									</>
							}

						</div>
						<div className="modal-footer p-0 pb-4 border-0">
							<div className="row pt-2 pb-0 w-100 my-0">
								<div className='col-4 d-flex align-items-center justify-content-start '>
									{
										addedFunds ?

											<PrimaryLink name="Go back and add more"
												handleClick={() => {
													setAddedFunds(false);
													setAllocation("");
													setShow(false);
												}} />

											:
											null
									}
								</div>
								<div className="col-lg-8">
									<div className="d-flex justify-content-end ">
										<SecondaryBtn
											name="Cancel"
											className={`${styles.e_cancel} e-cancel`}
											cancel="modal"
											handleClick={() => {
												setName("");
												setAllocation("");
												setSearch("");
												setFunds([]);
												setResponse(null);

											}} />
										{!addedFunds &&
											name && selectedFunds.length > 0 ?
											<PrimaryBtn
												name="Add funds"
												className={loader ? " e-btn-loader" : ""}
												handleClick={() => {
													setAddedFunds(true);

												}} />
											:
											!addedFunds &&
											<PrimaryBtn
												name="Add funds"
												className="e-disabled " />
										}
										{
											addedFunds && selectedFunds.filter((item) => item.allocation !== '').length === selectedFunds.length && selectedFunds.filter((item) => item.fund_alias !== null).length === selectedFunds.length ?
												<PrimaryBtn
													name="Add funds"
													className={loader ? " e-btn-loader" : ""}
													handleClick={() => {

														if (props.basket) {
															_saveFundToBasket()
														} else {
															_saveFund()
														}

													}} />
												:
												addedFunds &&
												<PrimaryBtn
													name="Add funds"
													className="e-disabled " />
										}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Error toast */}
				{
					error !== null ?
						<Toast data={error} type="error" id="1" />
						:
						null
				}

				{/* success toast */}
				{
					success !== null ?
						<Toast data={success} type="success" id="2" />
						:
						null
				}
			</div>
		</>
	)
}
