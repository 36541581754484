/*
 *   File : rebalance-details.js
 *   Author : https://evoqins.com
 *   Description : Basket Rebalance details modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect } from "react";

// Components
import { _closeModal } from "../../Helper/function";
import { PrimaryBtn } from "../Cta";

// Assets
import FundLogo from "../../Assets/Images/General/fund-navi-logo.png";
import ArrowDown from "../../Assets/Images/Kyc/left-arrow.svg";

function RebalanceDetailsModal(props) {

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onModalClose);
    }, []);

    return (
        <div className="modal fade e-manage-modal"
            data-bs-backdrop="static"
            id={"rebalance-details-modal"}
            tabIndex="-1"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content e-border-radius-8 e-modal-box-shadow">
                    <div className="modal-header justify-content-between pb-3 pt-2  px-2 border-0">
                        <h6 className='e-text-charleston-green e-font-16  e-font-manrope e-font-weight-700 line-height-20px mb-0'>
                            Rebalance details
                        </h6>
                        <button type="button"
                            id={`rebalance-details-modal`}
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onModalClose}></button>
                    </div>
                    <div className="modal-body pb-2 px-2 pt-0">
                        <p className="e-text-charleston-green e-font-14  e-font-manrope e-font-weight-500 line-height-18px mb-4">
                            Fund Changes
                        </p>
                        <div className="d-flex flex-column gap-3 p-3 e-border-radius-8 e-border-1px-platinum-gray">
                            <div className="d-flex gap-3 align-items-center">
                                <img src={FundLogo}
                                    width={64}
                                    alt=""
                                    className="e-border-radius-16px h-auto" />
                                <p className="e-text-charleston-green e-font-18  e-font-manrope e-font-weight-500 line-height-24px mb-0">
                                    Navi Flexi Cap Fund Direct Growth
                                </p>
                                <span className="ms-auto e-text-charleston-green e-font-18  e-font-manrope e-font-weight-500 line-height-24px">
                                    30%
                                </span>
                            </div>

                            <img src={ArrowDown}
                                width={24}
                                className="d-block text-center mx-auto rotate-min-90deg"
                                draggable={false}
                                alt='arrow' />

                            <div className="d-flex gap-3 align-items-center">
                                <img src={FundLogo}
                                    width={64}
                                    alt=""
                                    className="e-border-radius-16px h-auto" />
                                <p className="e-text-charleston-green e-font-18  e-font-manrope e-font-weight-500 line-height-24px mb-0">
                                    Navi Flexi Cap Fund Direct Growth
                                </p>
                                <span className="ms-auto e-text-charleston-green e-font-18  e-font-manrope e-font-weight-500 line-height-24px">
                                    50%
                                </span>
                            </div>
                        </div>

                        {/* button */}
                        <div className='pt-4 mt-2 text-center'>
                            <PrimaryBtn className={"py-2"}
                                cancel="modal"
                                name={"Understood"}
                                handleClick={props.onModalClose} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RebalanceDetailsModal;