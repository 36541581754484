/*
 *   File : pan.js
 *   Author : https://evoqins.com
 *   Description : PAN card verify page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';

// Components
import { PrimaryBtn } from '../../../Components/Cta';
import { CustomPhoneInput, CustomTextInput, RadioGroup } from '../../../Components/Form-Elements';
import { NRIWarningModal, VerifyPhoneAndEmailModal } from '../../../Components/Modal';
import service from '../../../Services/service';

// Custom data
const CITIZEN_SELECT = [
    {
        id: 2,
        display_name: "Yes"
    },
    {
        id: 1,
        display_name: "No"
    },
];

const PAN = (props) => {

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [pan, setPan] = useState("");
    const [panError, setPanError] = useState("");
    const [citizenSelect, setCitizenSelect] = useState(1);

    const [showPhoneEmailVerifyModal, setShowPhoneEmailVerifyModal] = useState(false);
    const [showNRIWarningModal, setShowNRIWarningModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        if (props.data) {
            setName(props.data.kyc_data.name);
            setEmail(props.data.kyc_data.email);
            setSelectedCountryCode(props.data.kyc_data.mobile_country_code);
            setPhoneNumber(props.data.kyc_data.mobile_country_code + props.data.kyc_data.mobile);
            setPan(props.data.kyc_data.pan ? props.data.kyc_data.pan.toUpperCase() : "");
        };
    }, [props.data]);

    // Open phone verify modal
    useEffect(() => {
        if (showPhoneEmailVerifyModal) {
            new Modal(document.querySelector("#verify-phone-email-modal")).show();
        }
    }, [showPhoneEmailVerifyModal]);

    useEffect(() => {
        if (showNRIWarningModal) {
            new Modal(document.querySelector("#nri-warning-modal")).show();
        }
    }, [showNRIWarningModal]);

    // handle name change
    const _handleNameChange = (value) => {
        setName(value);
        setNameError('');
    };

    // handle PAN change
    const _handlePanChange = (value) => {
        setPan(value.toUpperCase());
        setPanError('');
    };

    // handle phone number change
    const _handlePhoneNumberChange = (value, selected_country_code) => {
        if (value) {
            setPhoneNumber(value);
        } else {
            setPhoneNumber("");
        }

        setPhoneNumberError("");

        if (selected_country_code) {
            setSelectedCountryCode(selected_country_code);
        }
    };

    //handle email change
    const _handleEmailChange = (value) => {
        setEmail(value.toLowerCase());
        setEmailError("");
    };

    // Handle update step
    const _handleUpdateStep = (customer_id, email_otp) => {
        props.onUpdateStep(2, customer_id, email_otp);
    };

    //handle verify
    const _handleVerify = () => {

        let is_valid = true;

        // removing country code
        let phone_number = Number(phoneNumber.replace(selectedCountryCode, ""));
        // valid phone number and email pattern 
        let valid_phone = /^[6-9]\d{9}$/;
        let validate_email = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
        let validate_pan = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/; // validation of PAN

        // Validating name
        if (name.length === 0) {
            setNameError("Please enter the name");
            is_valid = false;
        }

        // Validating phone
        if (selectedCountryCode == "+91") {
            if (!valid_phone.test(phone_number)) {
                setPhoneNumberError("Please enter a valid 10-digit phone number");
                is_valid = false;
            }
        }

        // Validating email
        if (!validate_email.test(email)) {
            setEmailError("Please enter a valid email id");
            is_valid = false;
        }


        if (validate_pan.test(pan) === false) {
            if (pan.length === 0) {
                setPanError("Please enter PAN number");
            } else {
                setPanError("Please enter valid PAN to proceed");
            }
            is_valid = false;
        };

        // If Phone and pan valid open phone verify modal
        if (is_valid) {
            setLoading(true);
            _sendOTP();
        }

    };

    // handle close verify modal
    const _handleCloseVerifyModal = () => {
        setShowPhoneEmailVerifyModal(false);
        setLoading(false);
    };

    // handle close verify modal
    const _handleCloseNRIModal = () => {
        setShowNRIWarningModal(false);
    };

    //handle citizenship select
    const _handleCitizenSelect = (id) => {
        setCitizenSelect(id);
    };

    // API CALL  : Send phone otp and email otp
    // Validating KYC status by passing pan number
    const _sendOTP = () => {

        // removing country code
        let phone_number = Number(phoneNumber.replace(selectedCountryCode, ""));

        let is_post = true;
        let url = '/kyc/get-signin-otp';
        let data = {
            "name": name,
            "country_code": selectedCountryCode,
            "phone_number": phone_number,
            "email": email,
            "pan": pan,
            "type": null
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                if ((citizenSelect === 2 && response.data.is_kyc_compliant === false) || response.data.non_kyc_nri) {
                    setShowNRIWarningModal(true);
                } else {
                    // if (props.panError) {
                    //     props.onUpdateStep(2);
                    // } else {
                    //     setShowPhoneEmailVerifyModal(true)
                    //     setLoading(true);
                    // }
                    setShowPhoneEmailVerifyModal(true);
                    setLoading(true);
                }
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        });
    };


    return (
        <div className='row'>
            <div className="col-sm-6 col-lg-4">
                <h3 className='mb-3 e-text-chinese-black e-font-manrope e-font-weight-700  e-font-18'>
                    Let's get started
                </h3>

                {/* Name input */}
                <CustomTextInput label="Enter name"
                    type="text"
                    require={true}
                    placeholder="John doe"
                    value={name}
                    errorMsg={nameError}
                    handleChange={_handleNameChange} />

                {/* Phone input */}
                <CustomPhoneInput
                    label="Enter Phone number"
                    require={true}
                    placeholder="9876543210"
                    selectedCountryCode={selectedCountryCode}
                    className={phoneNumber ? `e-number-input-padding w-100 ` : `e-number-input-padding w-100`}
                    type="text"
                    value={phoneNumber}
                    error={phoneNumberError}
                    handleChange={_handlePhoneNumberChange} />

                {/* Email input */}
                <CustomTextInput label="Enter Email"
                    require
                    type="text"
                    placeholder="john@gmail.com"
                    value={email}
                    errorMsg={emailError}
                    handleChange={_handleEmailChange} />

                {/* PAN Input */}
                <CustomTextInput label="Enter PAN number"
                    type="text"
                    require={true}
                    maxLength={10}
                    placeholder="AFDSHJK890"
                    value={pan}
                    errorMsg={panError}
                    handleChange={_handlePanChange} />

                <div className='mb-2'>
                    <h6 className='e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14'>
                        Is user NRI ?
                    </h6>
                    <div className='d-flex flex-wrap gap-3'>
                        <RadioGroup data={CITIZEN_SELECT}
                            selected={citizenSelect}
                            handleSelect={_handleCitizenSelect} />
                    </div>
                </div>

                <div className='w-100 text-end mt-4' >
                    <PrimaryBtn name="Verify & continue"
                        className={"h-40"}
                        loading={loading}
                        disabled={loading}
                        handleClick={_handleVerify} />
                </div>
            </div>
            {
                showPhoneEmailVerifyModal &&
                <VerifyPhoneAndEmailModal
                    phone={Number(phoneNumber.replace(selectedCountryCode, ""))}
                    countryCode={selectedCountryCode}
                    indianUser={selectedCountryCode === "+91" ? true : false}
                    name={name}
                    email={email}
                    pan={pan}
                    onSuccess={_handleUpdateStep}
                    onCloseModal={_handleCloseVerifyModal} />
            }
            {
                showNRIWarningModal &&
                <NRIWarningModal onCloseModal={_handleCloseNRIModal} />
            }
        </div>
    )
}

export default PAN;

