import { combineReducers } from "redux";
import { GET_GENERAL_DATA, GET_INVESTMENT_TYPES, GET_ORDER_STATUS, GET_PAYMENT_STATUS, UPDATE_ACCESS_TOKEN } from "../Action/types";

const initialState = {
    ACCESS_TOKEN: null,
    GENERAL_DATA: {},
    ORDER_STATUS: [],
    PAYMENT_STATUS: [],
    INVESTMENT_TYPE_OPTIONS: [],
    ORDER_TYPE_OPTIONS: [
        { value: null, label: 'All' },
        { value: 1, label: 'Lumpsum' },
        { value: 2, label: 'SIP' },
        { value: 3, label: "Withdraw" },
        { value: 4, label: "Switch" }
    ],
    GOAL_OPTIONS: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ],
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ACCESS_TOKEN: {
            return {
                ...state,
                ACCESS_TOKEN: action.payload
            };
        }
        case GET_GENERAL_DATA:
            return {
                ...state,
                GENERAL_DATA: action.payload
            };
        case GET_ORDER_STATUS:
            return {
                ...state,
                ORDER_STATUS: action.payload
            };
        case GET_PAYMENT_STATUS:
            return {
                ...state,
                PAYMENT_STATUS: action.payload
            };
        case GET_INVESTMENT_TYPES:
            return {
                ...state,
                INVESTMENT_TYPE_OPTIONS: action.payload
            };

        default:
            return state
    }
}

const rootReducer = combineReducers({
    INDEX_FUND_STORE: Reducer
});
export default rootReducer;