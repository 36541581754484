/*import package*/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

/*import containers*/
// import Contents from './containers/contents.js';
import { Login, ForgotPassword, ChangePassword } from './Containers/Auth';
import { Dashboard } from './Containers/Dashboard';
import { Users, UserDetails } from './Containers/Users';
import { Notification } from './Containers/Notifications';
// import ManageReturns from './Containers/Returns';
import { RiskFunds } from './Containers/RiskFunds';
import { ManageBaskets } from './Containers/Baskets';
import { ManageContent } from './Containers/ManageContent'
import { ValuePairs } from './Containers/Pairs'
// import { ManageBanner } from './Containers/Banner'
import { ManageCampaign, CampaignDetails } from './Containers/Campaign';
import { AllNotification } from './Containers/Allnotification';
import { Orders } from './Containers/Orders';
import { ManageSupport } from './Containers/Support';
import { Questionnaire } from './Containers/Questionnaire';
import { Redemption } from './Containers/Redemption';
import { ProspectList, ProspectDetail } from './Containers/Prospects';
import { ConfigureGoal } from './Containers/Goal';
import { CheckKyc } from './Containers/KYC';
import { FamilyDetail, FamilyList } from './Containers/Family';


/*import component*/
import { Footer, InnerHeader, Sidebar } from './Components/Navigation/index';
import { ToastContainer } from 'react-toastify';
import service from './Services/service';
import BasketVersionHistory from './Containers/Baskets/basket-version-history';


export default function App() {

    const ACCESS_TOKEN = useSelector((store) => store.INDEX_FUND_STORE.ACCESS_TOKEN);
    const dispatch = useDispatch();

    const [collapseMenu, setCollapseMenu] = useState(false);
    const [width, setWidth] = useState(window.screen.width)

    const [accessToken, setAccessToken] = useState(localStorage.getItem("access_token_ifsh_admin"));

    useEffect(() => {
        window.addEventListener('resize', (event) => {
            setWidth(event.target.innerWidth)
            if (event.target.innerWidth <= 1024) {
                setCollapseMenu(true)
            } else {
                setCollapseMenu(false)
            }
        })
    }, []);

    useEffect(() => {

        let access_token = localStorage.getItem("access_token_ifsh_admin");
        dispatch({
            type: 'UPDATE_ACCESS_TOKEN',
            payload: access_token,
        });
        setAccessToken(access_token);

    }, [ACCESS_TOKEN]);

    useEffect(() => {
        let access_token = localStorage.getItem("access_token_ifsh_admin");
        setAccessToken(access_token);
    }, []);

    useEffect(() => {
        // For KYC Flow
        getGeneralData();
    }, []);

    useEffect(() => {

        window.addEventListener('popstate', function () {

            // Removing modal backdrop for all modals when browser back button clicked
            if (document.querySelector(".modal-backdrop")) {
                document.querySelector(".modal-backdrop").remove();
                document.body.style.overflow = 'unset';
            };

        });

    }, []);

    const getGeneralData = () => {
        let is_post = false;
        let url = '/general/get';
        service(is_post, url).then((response) => {
            if (response.status_code === 200) {
                dispatch({ type: "GET_GENERAL_DATA", payload: response.data })
            } else {
            }
        })
    };

    return (
        <>
            {
                accessToken ?
                    <Router>
                        <div className="container-fluid d-flex flex-column flex-lg-row p-0">
                            <div className={collapseMenu || width <= 1024 ? "e-sidebar-collapsed sticky-top p-0 " : "e-sidebar-wrap sticky-top p-0"}>
                                <Sidebar is_menu_collapse={collapseMenu} width={width} />
                            </div>
                            <div className="e-content-wrap position-relative p-0">
                                <InnerHeader collapse={() => { setCollapseMenu(!collapseMenu) }} />
                                <div className="p-5 pt-4 ms-2 e-content-body mb-4">
                                    <Routes>
                                        <Route exact path="/" element={<Dashboard />} />
                                        {/* <Route path="/home" element={<Contents />} /> */}
                                        <Route path="/users" element={<Users />} />
                                        <Route path="/user-detail" element={<UserDetails />} />
                                        <Route path="/notifications" element={<Notification />} />
                                        <Route path="/funds" element={<RiskFunds />} />
                                        <Route path="/baskets" element={<ManageBaskets />} />
                                        <Route path="/funds" element={<RiskFunds />} />
                                        <Route path="/baskets" element={<ManageBaskets />} />
                                        <Route path="/baskets/version-history" element={<BasketVersionHistory />} />
                                        <Route path="/content" element={<ManageContent />} />
                                        {/* <Route path="/returns" element={<ManageReturns />} /> */}
                                        <Route path="/config" element={<ValuePairs />} />
                                        {/* <Route path="/banner" element={<ManageBanner />} /> */}

                                        <Route path="/family-users" element={<FamilyList />} />
                                        <Route path="/family-users/family-detail" element={<FamilyDetail />} />

                                        <Route path="/support" element={<ManageSupport />} />
                                        <Route path="/redemption" element={<Redemption />} />
                                        <Route path="/risk-questionnaire" element={<Questionnaire />} />
                                        <Route path="/campaign" element={<ManageCampaign />} />
                                        <Route path="/campaign-detail" element={<CampaignDetails />} />
                                        <Route path="/orders" element={<Orders />} />
                                        <Route path="/all-notification" element={<AllNotification />} />

                                        <Route path="/onboarding-users" element={<ProspectList />} />
                                        <Route path="/onboarding-users/user-detail" element={<ProspectDetail />} />
                                        <Route path="/onboarding-users/create-user" element={<CheckKyc />} />
                                        <Route path="/create-investments" element={<ConfigureGoal />} />
                                        <Route path="/change-password" element={<ChangePassword />} />
                                    </Routes>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </Router>
                    :
                    <Router>
                        <Routes>
                            <Route exact path="/" element={<Login />} />
                            <Route exact path="/forgot-password" element={<ForgotPassword />} />
                            <Route exact path="*" element={<Navigate to="/" replace={true} />} />
                        </Routes>
                    </Router>
            }

            <ToastContainer position="bottom-right"
                autoClose={3000}
                className="e-toast" />
        </>
    )

}