/*
 *   File : checkbox.js
 *   Author : https://evoqins.com
 *   Description : Radio button component
 *   Integrations : NA
 *   Version : 1.0.0
*/


// Assets
import Active from "../../Assets/Images/General/check-box-active.svg";
import InActive from "../../Assets/Images/General/check-box-inactive.svg";

const CheckBox = (props) => {
    return (
        <div className='d-inline-flex gap-2 align-items-center cursor-pointer'
            onClick={props.handleCheck}>
            <img src={props.check === false ? InActive : Active}
                draggable={false}
                className='cursor-pointer'
                alt='checkbox' />
            <label className={`e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mt-1`}>
                {props.label}
            </label>
        </div>
    )
};

export default CheckBox;