/*
 *   File : basket.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Basket file for Manage baskets container;
 *   Integrations :28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
*/

/*import packages*/
import { useState, useEffect } from 'react';
import { Modal } from 'bootstrap';

/*import components*/
import { Accordion, DataTable, FundsTab, } from '../../Components/DataFormating/index';
import { Loader } from '../../Components/Loader';
import { TextBtn } from '../../Components/Cta';
import { EmptyNote, Toast } from '../../Components/Note';
import { FundClass } from '../../Components/Modal';

//Assets
// import Rebalance from '../../Assets/Images/Cta/rebalance.svg';
import LeftArrow from "../../Assets/Images/Kyc/left-arrow.svg";

/*import services */
import service from '../../Services/service';
import { useNavigate } from 'react-router-dom';

// Custom data
const VERSION_HISTORY = [
    {
        id: 1,
        version_id: 567,
        version_name: "Index balanced version 146",
        created_on: '15th Jan 2025 12.58PM',
        rebalance_count: 67
    },
    {
        id: 2,
        version_id: 577,
        version_name: "Index balanced version 176",
        created_on: '18th Jan 2025 12.58PM',
        rebalance_count: 879
    },
    {
        id: 3,
        version_id: 587,
        version_name: "Index balanced version 186",
        created_on: '20th Jan 2025 12.58PM',
        rebalance_count: 67
    },
    {
        id: 4,
        version_id: 597,
        version_name: "Index balanced version 216",
        created_on: '21th Jan 2025 12.58PM',
        rebalance_count: 123
    },
    {
        id: 5,
        version_id: 667,
        version_name: "Index balanced version 346",
        created_on: '25th Jan 2025 12.58PM',
        rebalance_count: 133
    },
    {
        id: 1,
        version_id: 567,
        version_name: "Index balanced version 146",
        created_on: '15th Jan 2025 12.58PM',
        rebalance_count: 67
    },
    {
        id: 2,
        version_id: 577,
        version_name: "Index balanced version 176",
        created_on: '18th Jan 2025 12.58PM',
        rebalance_count: 879
    },
    {
        id: 3,
        version_id: 587,
        version_name: "Index balanced version 186",
        created_on: '20th Jan 2025 12.58PM',
        rebalance_count: 67
    },
    {
        id: 4,
        version_id: 597,
        version_name: "Index balanced version 216",
        created_on: '21th Jan 2025 12.58PM',
        rebalance_count: 123
    },
    {
        id: 5,
        version_id: 667,
        version_name: "Index balanced version 346",
        created_on: '25th Jan 2025 12.58PM',
        rebalance_count: 133
    },
];


const Basket = (props) => {

    const navigate = useNavigate();

    const [fundData, setFundData] = useState({});
    const [fundLoader, setFundLoader] = useState(true);
    const [syncLoader, setSyncLoader] = useState(false);
    //eslint-disable-next-line
    const [updated, setUpdated] = useState("");
    // const [debtOptions, setDebtOptions] = useState([]);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    // const [syncLoader, setSyncLoader] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [basketData, setBasketData] = useState([]);
    const [basketId, setBasketId] = useState(null);
    const [addFund, setAddFund] = useState(false);
    const [totalAllocation, setTotalAllocation] = useState(0);

    // const [investmentId, setInvestmentId] = useState(2)
    const [showVersionHistory, setShowVersionHistory] = useState(false);
    const [versionLoader, setVersionLoader] = useState(false);


    const VERSION_HISTORY_COLUMNS = [
        {
            name: 'Version ID',
            selector: row => row.version_id,
            // sortable: true,
            left: true,
            width: "120px",
            // defaultSortAsc: false,
            cell: row => row.version_id || "--",
            conditionalCellStyles: [
                {
                    when: () => true, // Applies to all rows
                    style: {
                        paddingLeft: "20px",
                    },
                },
            ],
        },
        {
            name: 'Version name',
            selector: row => row.version_name,
            // sortable: true,
            left: true,
            width: "240px",
            // defaultSortAsc: false,
            cell: row => row.version_name || "--"
        },
        {
            name: 'Created on',
            selector: row => row.created_on,
            // sortable: true,
            left: true,
            width: "240px",
            // defaultSortAsc: false,
            cell: row => row.created_on || "--"
        },
        {
            name: 'No. of people sent for Rebalance',
            selector: row => row.rebalance_count,
            // sortable: true,
            left: true,
            minWidth: "220px",
            // defaultSortAsc: false,
            cell: row => row.rebalance_count || "--"
        },
    ];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {

        _getBasketList()
        //eslint-disable-next-line
    }, []);

    useEffect(() => {


        if (basketId) {
            let temp_allocation = [];
            Object.values(fundData).map((item) => {
                Object.values(item).map((fund) => {
                    fund.map(item => {
                        return temp_allocation.push(item);
                    })
                })
            });

            temp_allocation = temp_allocation.filter(item => item.basket_id == basketId).reduce((a, b) => a + b.allocation, 0)
            setTotalAllocation(temp_allocation);

        }

    }, [fundData, basketId]);

    useEffect(() => {
        if (addFund) {
            _openAddFundModal()
        }
    }, [addFund])

    // useEffect(() => {

    //     const temp_allocation_length_arr = []

    //     //eslint-disable-next-line
    //     if (Object.keys(fundData).filter((item) => item === `${basketId}`).length > 0) {

    //         Object.values(fundData[`${basketId}`]).map((item) => {
    //             item.map((fund) => {
    //                 return (
    //                     temp_allocation_length_arr.push(fund.allocation)
    //                 );
    //             })
    //         });

    //         setAllocation(temp_allocation_length_arr.reduce((a, b) => a + b, 0))

    //     } else {
    //         setAllocation(0);
    //     }

    // }, [basketId, fundData])

    useEffect(() => {
        if (Object.values(editedData).length > 0) {
            // var myModal = new Modal(document.getElementById("editAllocations"), {});
            // myModal.show();
        }
    }, [editedData])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error])

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        }
    }, [success]);

    // handle show version history details - basket
    const _handleShowVersionHistory = () => {
        setShowVersionHistory(true);
    };

    // handle back to detail
    const _handleBack = () => {
        setShowVersionHistory(false);
    };

    // handle navigate to version history
    const _handleNavigateToVersionHistory = (data) => {
        navigate("version-history");
    };

    /* ---- get mutual funds ---*/
    const _getBasketList = (loader = true) => {
        setFundLoader(loader);
        const url = '/baskets/list'
        service(false, url).then((response) => {
            if (response.status_code === 200) {
                let basket_tabs = [...basketData]
                Object.values(response.data).map((item) => (
                    // eslint-disable-next-line
                    item.map((fund) => {
                        let basket_index = basket_tabs.findIndex((item) => item.id === fund.basket_id);
                        if (basket_index < 0) {
                            basket_tabs.push({
                                name: fund.name,
                                id: fund.basket_id
                            })
                        } else {
                            basket_tabs.splice(basket_index, 1);
                            basket_tabs.push({
                                name: fund.name,
                                id: fund.basket_id
                            })
                        }

                    })

                ));
                setBasketData(basket_tabs);
                if (loader === true) {
                    setBasketId(basket_tabs[0].id);
                }
                const resultObject = {};

                for (const basketId in response.data) {
                    const dataArray = response.data[basketId];
                    resultObject[basketId] = {};

                    dataArray.forEach(item => {
                        const assetClass = item.asset_class;
                        if (!resultObject[basketId][assetClass]) {
                            resultObject[basketId][assetClass] = [];
                        }
                        resultObject[basketId][assetClass].push(item);
                    });
                }

                setFundData(resultObject);
                setFundLoader(false);
            }
            else {
                setFundLoader(false);
                setFundData([]);
            }
        });

    };


    // Sync data to calculate min investment
    const _syncData = () => {

        setSyncLoader(true);

        const url = '/fund/sync-data';
        const data = {
            "investment_style_id": 2
        };

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess(response.message);
            }
            else {
                setError(response.message);
            }

            setSyncLoader(false);
        });

    };


    /* ------ open add fund Modal -----*/
    const _openAddFundModal = () => {
        var myModal = new Modal(document.getElementById("addFundClass"), {});
        myModal.show();
    }


    return (
        <>

            <div className="row pb-5 position-relative">
                <div className="col-lg-12">
                    <h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
                        Manage baskets
                    </h3>
                    {
                        showVersionHistory === false ?

                            <div className="card e-dashboard-card e-faq-card  border-0 p-4 ">

                                <div className='col-12 mb-3'>
                                    <div className="d-flex justify-content-between">
                                        <FundsTab data={basketData}
                                            funds={true}
                                            index={basketId}
                                            setTab={(tab_index) => {
                                                setBasketId(tab_index);
                                            }} />
                                        {
                                            fundLoader === false &&
                                            <TextBtn name="Version History" handleClick={_handleShowVersionHistory} />
                                        }
                                    </div>
                                </div>

                                {/*----------- list of funds added ------------------*/}

                                {
                                    fundLoader ?
                                        <Loader />
                                        :
                                        <>

                                            {/* {

                                        fundData[basketId].map((fund) => {
                                            return (
                                                <div className={`e-basket-section mt-2`}>
                                                    <div className='d-flex align-items-center mb-2 ps-1'>{fund.asset_class}
                                                        <h6 className='mb-0 ps-2 e-allocation'> {fund.allocation}%</h6>
                                                        <span onClick={() => _openAddFundModal(fundData[basketId])} edit="true" className="ps-2 ">
                                                            <img src={require('../../Assets/Images/DataFormating/update.svg').default} alt="" />
                                                            <span className='e-update'>Update</span>
                                                        </span>
                                                    </div>

                                                    <div className={`e-basket-body row pt-3`}>

                                                        <div className={`col-lg-11`}>
                                                            <div className='d-flex'>
                                                                <div>{fund.fund_name}</div>
                                                                <span className='mb-0 ps-2 e-body-allocation'>{fund.fund_alias ? `(${fund.fund_alias})` : null}</span>
                                                            </div>
                                                            <h6 className='e-body-allocation'>Allocation: {fund.allocation}%</h6>

                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })

                                    } */}
                                            <div className="col-lg-12 ">
                                                <Accordion
                                                    data={fundData[basketId]}
                                                    basket={true}
                                                    updatedFund={(data) => {
                                                        _getBasketList(false);
                                                        setUpdated(data);
                                                    }}
                                                    setEditedData={(item) => {
                                                        setEditedData(item);
                                                    }}
                                                    close={() => {
                                                        _getBasketList(false)
                                                    }}
                                                />

                                            </div>
                                            <div className="row mx-0">
                                                <div className="e-add-sec col-lg-12 "
                                                    onClick={() => { setAddFund(true); setEditedData({}) }}>
                                                    <h6>
                                                        <span className="pe-3"><img src={require('../../Assets/Images/DataFormating/add-new.svg').default} alt="add" />
                                                        </span>
                                                        Add New
                                                    </h6>
                                                </div>
                                            </div>

                                        </>
                                }

                                {/* sync data buttton */}
                                <div className='row mt-auto'>
                                    <div className='col-lg-5 offset-lg-7 d-flex justify-content-end'>
                                        {/* Will be enabled in future */}
                                        {/* <SecondaryBtn
                                    name="Trigger balance"
                                    className="e-submit py-2 px-3"
                                    img={<img src={Rebalance} alt="" />}
                                    handleClick={() => {
                                        // _syncData()
                                    }}
                                /> */}
                                        {/* <PrimaryBtn
                                    name="Save"
                                    className={
                                        syncLoader ? "e-btn-loader "
                                            :
                                            "ms-2"
                                    }
                                    handleClick={() => {
                                        _syncData()
                                    }} /> */}
                                    </div>

                                </div>
                            </div>
                            :
                            // Basket edit Version-history 
                            <div className="card e-dashboard-card e-faq-card  border-0 py-4 px-0 min-vh-100 pb-5">
                                <div className='d-flex gap-3 mb-4 align-items-center px-4 cursor-pointer'
                                    onClick={_handleBack}>
                                    <img src={LeftArrow}
                                        draggable={false}
                                        alt='arrow' />
                                    <span className='e-text-dark-silver e-font-manrope e-font-weight-600  e-font-16 line-height-20px'>
                                        Back to Details
                                    </span>
                                </div>
                                {
                                    versionLoader ?
                                        <Loader />
                                        :
                                        VERSION_HISTORY.length > 0 ?
                                            <div className="col-lg-12 px-0 min-vh-100 pb-5">
                                                <DataTable
                                                    pagination={false}
                                                    columns={VERSION_HISTORY_COLUMNS}
                                                    data={VERSION_HISTORY}
                                                    selectableRows={false}
                                                    rowClick={(row) => _handleNavigateToVersionHistory(row)}
                                                    customStyles={
                                                        {
                                                            rows: {
                                                                style: {
                                                                    paddingLeft: '8px',
                                                                    border: "none !important"
                                                                },
                                                            },
                                                            headRow: {
                                                                style: {
                                                                    paddingLeft: "8px",
                                                                },
                                                            },

                                                        }
                                                    }
                                                />
                                            </div>
                                            :
                                            <EmptyNote title="No data found" note="No version history found" />
                                }
                            </div>
                    }
                </div>
            </div>
            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }

            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }


            {/*----------- Modal to add fund ------------------*/}

            <FundClass
                totalAllocation={totalAllocation}
                investmentStyle={basketId}
                editedData={editedData}
                basket={true}
                close={() => {
                    setAddFund(false);
                    setEditedData({});
                }}
                addedFund={(data) => { _getBasketList(false); setUpdated(data) }} />

        </>
    )
}

export default Basket;