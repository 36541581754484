/*
 *   File : basket-version-history.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Basket edit version history page;
 *   Integrations :
 *   Version : v1.0
 *   Created : 30-01-2025
*/

import { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import DataTable from "react-data-table-component";

// Components
import { Link, useNavigate } from "react-router-dom"
import { Accordion, FundsTab } from "../../Components/DataFormating";
import { TextBtn } from "../../Components/Cta";
import { RebalanceDetailsModal, TriggerRebalanceModal } from "../../Components/Modal";


// Custom data
const TABS = [
    {
        id: 1,
        name: "Current version"
    },
    {
        id: 2,
        name: "Previous version history"
    },
    {
        id: 3,
        name: "Users invested"
    },
    {
        id: 4,
        name: "Lumpsum Rebalance orders"
    },
    {
        id: 5,
        name: "SIP Rebalance orders"
    },
];

const CURRENT_VERSION = {
    Index: [
        {
            "id": 27,
            "basket_id": 6,
            "name": "Index SIP",
            "fund_name": "Franklin India Index Fund- NSE Nifty 50 Index Fund - Growth",
            "scheme_code": "20527",
            "allocation": 20,
            "asset_class": "Index",
            "fund_alias": "Mid cap",
            "investment_style": "2"
        },
        {
            "id": 27,
            "basket_id": 6,
            "name": "Index SIP",
            "fund_name": "Kotak Nifty 50 Index Fund - Regular Plan-IDCW Payout (KOTAKUP)",
            "scheme_code": "20527",
            "allocation": 80,
            "asset_class": "Index",
            "fund_alias": "KOTAKUP",
            "investment_style": "2"
        }
    ]
};

const PREVIOUS_VERSION = [
    {
        version_id: 567,
        version_name: "Index balanced version 146",
        created_on: '15th Jan 2025 12.58PM',
        rebalance_sent: 67,
        rebalance_done: 47
    },
    {
        version_id: 557,
        version_name: "Index balanced version 136",
        created_on: '12th Jan 2025 12.58PM',
        rebalance_sent: 108,
        rebalance_done: 35
    },
    {
        version_id: 587,
        version_name: "Index balanced version 136",
        created_on: '25th Jan 2025 12.58PM',
        rebalance_sent: 208,
        rebalance_done: 45
    },
];

const INVESTED_USERS = [
    {
        user_id: "IFSH0101",
        name: 'Ralph Edwards',
        email: "xyz@gmail.com",
        phone: "8976456729",
        aum: 50000,
        created_by: "Vijay",
        rebalance_status: "Success",

    },
    {
        user_id: "IFSH0102",
        name: 'Salph Edwards',
        email: "xyz@gmail.com",
        phone: "8976456729",
        aum: 60000,
        created_by: null,
        rebalance_status: "Pending",
    },
    {
        user_id: "IFSH0103",
        name: 'Aalph Edwards',
        email: "xyz@gmail.com",
        phone: "8976456729",
        aum: 40000,
        created_by: "Vijay",
        rebalance_status: "Failed",
    },
    {
        user_id: "IFSH0101",
        name: 'Ralph Edwards',
        email: "xyz@gmail.com",
        phone: "8976456729",
        aum: 50000,
        created_by: "Vijay",
        rebalance_status: "Success",
    },
    {
        user_id: "IFSH0102",
        name: 'Salph Edwards',
        email: "xyz@gmail.com",
        phone: "8976456729",
        aum: 60000,
        created_by: null,
        rebalance_status: "Pending",
    },
    {
        user_id: "IFSH0103",
        name: 'Aalph Edwards',
        email: "xyz@gmail.com",
        phone: "8976456729",
        aum: 40000,
        created_by: "Vijay",
        rebalance_status: "Failed",
    },
    {
        user_id: "IFSH0101",
        name: 'Ralph Edwards',
        email: "xyz@gmail.com",
        phone: "8976456729",
        aum: 50000,
        created_by: "Vijay",
        rebalance_status: "Success",
    },
    {
        user_id: "IFSH0102",
        name: 'Salph Edwards',
        email: "xyz@gmail.com",
        phone: "8976456729",
        aum: 60000,
        created_by: null,
        rebalance_status: "Pending",
    },
    {
        user_id: "IFSH0103",
        name: 'Aalph Edwards',
        email: "xyz@gmail.com",
        phone: "8976456729",
        aum: 40000,
        created_by: "Vijay",
        rebalance_status: "Failed",
    },

];

const LUMPSUM_REBALANCE_ORDERS = [
    {
        user_id: "IFSH0101",
        name: 'Ralph Edwards',
        goal_name: "Life Goal : Bike",
        total_amount: 50000,
        transaction_type: "Switch",
        status: "Completed",
    },
    {
        user_id: "IFSH0102",
        name: 'Ealph Edwards',
        goal_name: "Life Goal : Home",
        total_amount: 20000,
        transaction_type: "Invest More",
        status: "Pending",
    },
    {
        user_id: "IFSH0103",
        name: 'Qalph Edwards',
        goal_name: "Life Goal : Education",
        total_amount: 40000,
        transaction_type: "Invest More",
        status: "Failed",
    },
    {
        user_id: "IFSH0101",
        name: 'Ralph Edwards',
        goal_name: "Life Goal : Bike",
        total_amount: 50000,
        transaction_type: "Switch",
        status: "Completed",
    },
    {
        user_id: "IFSH0102",
        name: 'Ealph Edwards',
        goal_name: "Life Goal : Home",
        total_amount: 20000,
        transaction_type: "Invest More",
        status: "Pending",
    },
    {
        user_id: "IFSH0103",
        name: 'Qalph Edwards',
        goal_name: "Life Goal : Education",
        total_amount: 40000,
        transaction_type: "Invest More",
        status: "Failed",
    },
    {
        user_id: "IFSH0101",
        name: 'Ralph Edwards',
        goal_name: "Life Goal : Bike",
        total_amount: 50000,
        transaction_type: "Switch",
        status: "Completed",
    },
    {
        user_id: "IFSH0102",
        name: 'Ealph Edwards',
        goal_name: "Life Goal : Home",
        total_amount: 20000,
        transaction_type: "Invest More",
        status: "Pending",
    },
    {
        user_id: "IFSH0103",
        name: 'Qalph Edwards',
        goal_name: "Life Goal : Education",
        total_amount: 40000,
        transaction_type: "Invest More",
        status: "Failed",
    },
];

const SIP_REBALANCE_ORDERS = [
    {
        user_id: "IFSH0101",
        name: 'Ralph Edwards',
        goal_name: "Life Goal : Bike",
        total_amount: 50000,
        transaction_type: "Switch",
        status: "Completed",
    },
    {
        user_id: "IFSH0102",
        name: 'Ealph Edwards',
        goal_name: "Life Goal : Home",
        total_amount: 20000,
        transaction_type: "Invest More",
        status: "Pending",
    },
    {
        user_id: "IFSH0103",
        name: 'Qalph Edwards',
        goal_name: "Life Goal : Education",
        total_amount: 40000,
        transaction_type: "Invest More",
        status: "Failed",
    },
    {
        user_id: "IFSH0101",
        name: 'Ralph Edwards',
        goal_name: "Life Goal : Bike",
        total_amount: 50000,
        transaction_type: "Switch",
        status: "Completed",
    },
    {
        user_id: "IFSH0102",
        name: 'Ealph Edwards',
        goal_name: "Life Goal : Home",
        total_amount: 20000,
        transaction_type: "Invest More",
        status: "Pending",
    },
    {
        user_id: "IFSH0103",
        name: 'Qalph Edwards',
        goal_name: "Life Goal : Education",
        total_amount: 40000,
        transaction_type: "Invest More",
        status: "Failed",
    },
    {
        user_id: "IFSH0101",
        name: 'Ralph Edwards',
        goal_name: "Life Goal : Bike",
        total_amount: 50000,
        transaction_type: "Switch",
        status: "Completed",
    },
    {
        user_id: "IFSH0102",
        name: 'Ealph Edwards',
        goal_name: "Life Goal : Home",
        total_amount: 20000,
        transaction_type: "Invest More",
        status: "Pending",
    },
    {
        user_id: "IFSH0103",
        name: 'Qalph Edwards',
        goal_name: "Life Goal : Education",
        total_amount: 40000,
        transaction_type: "Invest More",
        status: "Failed",
    },
];

const CUSTOM_TABLE_STYLES = {
    rows: {
        style: {
            paddingLeft: '8px',
            border: "none !important"
        },
    },
    headRow: {
        style: {
            paddingLeft: "8px",
        },
    },

};

function BasketVersionHistory() {

    const navigate = useNavigate();
    const [activeTabID, setActiveTabID] = useState(1);
    const [rebalanceData, setRebalanceData] = useState("");

    const [showRebalanceDetailModal, setShowRebalanceDetailModal] = useState(false);
    const [showTriggerRebalanceModal, setShowTriggerRebalanceModal] = useState(false);


    const PREVIOUS_VERSION_COLUMN = [
        {
            name: 'Version ID',
            selector: row => row.version_id,
            left: true,
            width: "120px",
            defaultSortAsc: false,
            cell: row => row.version_id || "--",
            conditionalCellStyles: [
                {
                    when: () => true, // Applies to all rows
                    style: {
                        paddingLeft: "16px",
                    },
                },
            ],
        },
        {
            name: 'Version Name',
            selector: row => row["version_name"] || "--",
            minWidth: "240px",
            cell: row => (
                <span>{row["version_name"] || "--"}</span>
            ),
        },
        {
            name: 'Created on',
            selector: row => row.created_on,
            // sortable: true,
            left: true,
            width: "220px",
            // defaultSortAsc: false,
            cell: row => row.created_on || "--"
        },
        {
            name: 'No. of people sent for Rebalance',
            selector: row => row.rebalance_count,
            // sortable: true,
            left: true,
            minWidth: "280px",
            // defaultSortAsc: false,
            cell: row => row.rebalance_sent || "--"
        },
        {
            name: 'No. of Rebalance done',
            selector: row => row.rebalance_count,
            // sortable: true,
            left: true,
            minWidth: "200px",
            // defaultSortAsc: false,
            cell: row => row.rebalance_done || "--"
        },
        {
            // name: 'History',
            // selector: row => row.status,
            minWidth: "200px",
            cell: row => (
                <span>
                    <TextBtn name="View rebalance history" className="e-text-primary text-decoration-underline" />
                </span>
            ),
        },
    ];

    const INVESTED_USERS_COLUMNS = [
        {
            name: 'User ID',
            selector: row => row.user_id,
            sortable: true,
            left: true,
            width: "120px",
            defaultSortAsc: false,
            cell: row => row.user_id || "--",
            conditionalCellStyles: [
                {
                    when: () => true, // Applies to all rows
                    style: {
                        paddingLeft: "16px",
                    },
                },
            ],
        },
        {
            name: 'Name',
            selector: row => row["name"].toLowerCase(),
            sortable: true,
            minWidth: "200px",
            cell: row => (
                <span data-tag="allowRowEvents"
                    className="e-link link-offset-1"
                    onClick={() => _handleNavigateToUserDetail(row.user_id)}>
                    {row["name"]}
                </span>
            ),
        },
        {
            name: 'Email',
            selector: row => row["email"] || "--",
            minWidth: "200px",
            cell: row => (
                <span data-tag="allowRowEvents">{row["email"] || "--"}</span>
            ),
        },
        {
            name: 'Phone',
            selector: row => row["phone"],
            minWidth: "140px",
        },
        {
            name: 'AUM',
            selector: row => row.aum ? row.aum : 0,
            sortable: true,
            minWidth: "160px",
            right: false,
            cell: row => <>
                {
                    row.aum ?
                        "₹ " + row.aum.toLocaleString('en-IN')
                        :
                        "₹ 0"
                }
            </>
        },
        {
            name: 'Added by',
            selector: row => new Date(row["created_by"]).valueOf(),
            minWidth: "120px",
            sortable: true,
            cell: row => (
                <span>{row.created_by === null ? "User" : "Admin"}</span>
            ),
        },
        {
            name: 'Latest Rebalanced Status',
            selector: row => row.rebalance_status,
            minWidth: "220px",
            cell: row => (
                <span className={`${row.rebalance_status === "Success" ? "e-text-caribbean-green" : row.rebalance_status === "Pending" ? "e-text-orange" : "e-text-red"} e-font-manrope e-font-weight-600  e-font-14 line-height-20px`}>
                    {row.rebalance_status}
                </span>
            ),
        },
        {
            name: 'History',
            selector: row => row.status,
            minWidth: "120px",
            cell: row => (
                <TextBtn name="View"
                    className="e-text-primary text-decoration-underline"
                    handleClick={() => _handleNavigateToUserDetail(row.user_id)} />
            ),
        },
    ];

    const LUMPSUM_ORDERS_COLUMNS = [
        {
            name: 'User ID',
            selector: row => row.user_id,
            sortable: true,
            left: true,
            width: "120px",
            defaultSortAsc: false,
            cell: row => row.user_id || "--",
            conditionalCellStyles: [
                {
                    when: () => true, // Applies to all rows
                    style: {
                        paddingLeft: "16px",
                    },
                },
            ],
        },
        {
            name: 'Name',
            selector: row => row["name"].toLowerCase(),
            sortable: true,
            minWidth: "200px",
            cell: row => (
                <span data-tag="allowRowEvents" className="e-link link-offset-1">
                    {row["name"]}
                </span>
            ),
        },
        {
            name: 'Goal Name',
            selector: row => row["goal_name"].toLowerCase(),
            // sortable: true,
            minWidth: "200px",
            cell: row => (
                <span>
                    {row["goal_name"]}
                </span>
            ),
        },
        {
            name: 'Txn Type',
            selector: row => row["transaction_type"],
            // sortable: true,
            minWidth: "140px",
            cell: row => (
                <span>
                    {row["transaction_type"]}
                </span>
            ),
        },
        {
            name: 'Status',
            selector: row => row.status,
            minWidth: "150px",
            cell: row => (
                <span className={`${row.status === "Completed" ? "e-text-caribbean-green" : row.status === "Pending" ? "e-text-orange" : "e-text-red"} e-font-manrope e-font-weight-600  e-font-14 line-height-20px`}>
                    {row.status}
                </span>
            ),
        },
        {
            name: 'Rebalance Details',
            selector: row => row.status,
            minWidth: "180px",
            cell: row => (
                <span>
                    <TextBtn name="View Details"
                        className="e-text-primary text-decoration-underline"
                        handleClick={() => _handleShowRebalanceDetails(row.rebalance_data)} />
                </span>
            ),
        },
        {
            name: 'Total amount',
            selector: row => row.total_amount ? row.total_amount : 0,
            sortable: true,
            minWidth: "160px",
            right: false,
            cell: row => <>
                {
                    row.total_amount ?
                        "₹ " + row.total_amount.toLocaleString('en-IN')
                        :
                        "₹ 0"
                }
            </>
        },
    ];

    const SIP_ORDERS_COLUMNS = [
        {
            name: 'User ID',
            selector: row => row.user_id,
            sortable: true,
            left: true,
            width: "120px",
            defaultSortAsc: false,
            cell: row => row.user_id || "--",
            conditionalCellStyles: [
                {
                    when: () => true, // Applies to all rows
                    style: {
                        paddingLeft: "16px",
                    },
                },
            ],
        },
        {
            name: 'Name',
            selector: row => row["name"].toLowerCase(),
            sortable: true,
            minWidth: "200px",
            cell: row => (
                <span data-tag="allowRowEvents" className="e-link link-offset-1">
                    {row["name"]}
                </span>
            ),
        },
        {
            name: 'Goal Name',
            selector: row => row["goal_name"].toLowerCase(),
            // sortable: true,
            minWidth: "200px",
            cell: row => (
                <span>
                    {row["goal_name"]}
                </span>
            ),
        },
        {
            name: 'Txn Type',
            selector: row => row["transaction_type"],
            // sortable: true,
            minWidth: "140px",
            cell: row => (
                <span>
                    {row["transaction_type"]}
                </span>
            ),
        },
        {
            name: 'Status',
            selector: row => row.status,
            minWidth: "150px",
            cell: row => (
                <span className={`${row.status === "Completed" ? "e-text-caribbean-green" : row.status === "Pending" ? "e-text-orange" : "e-text-red"} e-font-manrope e-font-weight-600  e-font-14 line-height-20px`}>
                    {row.status}
                </span>
            ),
        },
        {
            name: 'Rebalance Details',
            selector: row => row.status,
            minWidth: "180px",
            cell: row => (
                <span>
                    <TextBtn name="View Details"
                        className="e-text-primary text-decoration-underline"
                        handleClick={() => _handleShowRebalanceDetails(row.rebalance_data)} />
                </span>
            ),
        },
        {
            name: 'Total amount',
            selector: row => row.total_amount ? row.total_amount : 0,
            sortable: true,
            minWidth: "160px",
            right: false,
            cell: row => <>
                {
                    row.total_amount ?
                        "₹ " + row.total_amount.toLocaleString('en-IN')
                        :
                        "₹ 0"
                }
            </>
        },
    ];


    // Open rebalance  details  modal
    useEffect(() => {
        if (showRebalanceDetailModal) {
            new Modal(document.querySelector("#rebalance-details-modal")).show();
        }
    }, [showRebalanceDetailModal]);

    // open trigger rebalance modal
    useEffect(() => {
        if (showTriggerRebalanceModal) {
            new Modal(document.querySelector("#trigger-rebalance-modal")).show();
        }
    }, [showTriggerRebalanceModal]);


    // handle tab change
    const _handleTabChange = (id) => {
        setActiveTabID(id);
    };

    // handle open trigger rebalance 
    const _handleTriggerRebalance = () => {
        setShowTriggerRebalanceModal(true);
    };

    // handle navigate to user detail
    const _handleNavigateToUserDetail = (user_id) => {
        navigate(`/user-detail?id=${1340 || user_id}`)
    };

    // handle show rebalance details
    const _handleShowRebalanceDetails = (data) => {
        setShowRebalanceDetailModal(true);
        setRebalanceData(data);
    };

    return (
        <div className="row mt-4 position-relative">
            <div className="col-12">
                <span className="e-breadcrumb-link">
                    <Link to="/baskets">Manage basket</Link>/ Version history
                </span>

            </div>
            <div className="col">
                <div className="card e-dashboard-card border-0 py-4 px-0 min-vh-100">
                    {/* Basic details  */}
                    <div className="mb-3 px-4">
                        <p className='e-text-chinese-black e-font-manrope e-font-weight-700  e-font-14 line-height-18px mb-3'>
                            Version name - Index Balanced Version 146
                        </p>
                        <p className='e-text-dark-silver e-font-manrope e-font-weight-600  e-font-14 line-height-18px mb-4'>
                            Last rebalanced on - 15 Jan 2025 02:05 PM
                        </p>

                        {/* Tabs */}
                        <FundsTab data={TABS}
                            funds={true}
                            index={activeTabID}
                            setTab={_handleTabChange} />
                    </div>

                    {/* Tabs data */}
                    {
                        // Current version data
                        activeTabID === 1 &&
                        <div className="col-lg-12 margin-top-26px px-4">
                            <Accordion
                                type={2}
                                data={CURRENT_VERSION}
                                basket={true}
                                handleTriggerRebalance={_handleTriggerRebalance}
                            />

                        </div>
                    }
                    {
                        // Previous version details
                        activeTabID === 2 &&
                        <div>
                            <DataTable
                                pagination={false}
                                columns={PREVIOUS_VERSION_COLUMN}
                                data={PREVIOUS_VERSION}
                                selectableRows={false}
                                // rowClick={(row) => _handleNavigateToVersionHistory(row)}
                                customStyles={CUSTOM_TABLE_STYLES}
                            />
                        </div>
                    }
                    {
                        // Invested user details
                        activeTabID === 3 &&
                        <div>
                            <DataTable
                                pagination={false}
                                columns={INVESTED_USERS_COLUMNS}
                                data={INVESTED_USERS}
                                selectableRows={false}
                                // rowClick={(row) => _handleNavigateToVersionHistory(row)}
                                customStyles={CUSTOM_TABLE_STYLES}
                            />
                        </div>
                    }
                    {
                        // Lumpsum Rebalance orders
                        activeTabID === 4 &&
                        <div>
                            <DataTable
                                pagination={false}
                                columns={LUMPSUM_ORDERS_COLUMNS}
                                data={LUMPSUM_REBALANCE_ORDERS}
                                selectableRows={false}
                                // rowClick={(row) => _handleNavigateToVersionHistory(row)}
                                customStyles={CUSTOM_TABLE_STYLES}
                            />
                        </div>
                    }
                    {
                        // SIP Rebalance orders
                        activeTabID === 5 &&
                        <div>
                            <DataTable
                                pagination={false}
                                columns={SIP_ORDERS_COLUMNS}
                                data={SIP_REBALANCE_ORDERS}
                                selectableRows={false}
                                // rowClick={(row) => _handleNavigateToVersionHistory(row)}
                                customStyles={CUSTOM_TABLE_STYLES}
                            />
                        </div>
                    }
                </div>
            </div>

            {/* Modals */}
            {
                showRebalanceDetailModal &&
                <RebalanceDetailsModal data={[]} onModalClose={() => setShowRebalanceDetailModal(false)} />
            }

            {
                showTriggerRebalanceModal &&
                <TriggerRebalanceModal onModalClose={() => setShowTriggerRebalanceModal(false)} />
            }

        </div>
    )
}

export default BasketVersionHistory